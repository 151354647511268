<template>
    <div>
        <link rel="icon" type="image/png" sizes="32x32" href="../../../public/assets/images/favicon.png">
        <link rel="stylesheet" href="../../../public/vendor/customer/css/animate.css"> 
        <link rel="stylesheet" href="../../../public/vendor/customer/css/animate.css" type="text/css">  
        <link href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900" rel="stylesheet">
        <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" type="text/css" rel="stylesheet">

        <link href="../../../public/vendor/customer/css/ionicons.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/owl.carousel.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/owl.theme.default.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/magnific-popup.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/bootstrap.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/jquery-ui.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/mCustomScrollbar.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/select2.min.css" type="text/css" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Nunito:200,600" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/style.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/responsive.css" type="text/css" rel="stylesheet">
       
    </div>
</template>
<script src="https://js.pusher.com/7.0/pusher.min.js"></script>
<script>

import 'vue-suggestion/dist/vue-suggestion.css';
import {VueSuggestion} from 'vue-suggestion'  ;
import itemTemplate from './ItemTemplate.vue';
import _ from 'lodash';
export default {
    name: 'CustomerHeader',
    components:{
        VueSuggestion,
        
    },
    data(){
        return {
            mainRoot: '',
            Authorization: '',
            showAfterLogin: false,
            showBeforeLogin: true,
            unreadNotification: 0,
            cartTotal: 0,
            name: '',
            profileImg: '',
            searchStore: null,
            curroutes: null,
            headerclass: 'banner',
            productCount:0,
            businessIds:[],
            flag:false,

            item: {},
            //item: '',
            items: [],
            searchItems:[],
            itemTemplate,
            rmvSearch:false,
            timeoutInMS: 120000,
            timeoutId:'',

            invoicesCount:0,
            invoices:[],
        }
    },
       props: {
    getCartFlag: {
      type: Boolean,
      required: false,
      default: false
    },
   },
   watch: {
    getCartFlag: {
            // the callback will be called immediately after the start of the observation
            immediate: true, 
            handler (val, oldVal) {
            if(this.flag == false)
            {
                this.flag = true;
            }
            else
            {
                //alert(2);
                this.getCartBusinesses();
            }
            
            //this.$emit('cartAdded1')
            }
        }
    },
    mounted(){
    },
    methods: {
    },
   
}

// The debounce function receives our function as a parameter
const debounce = (fn) => {

  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {
    
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) { 
        cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
        fn(...params);
    });

    } 
};


// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
const storeScroll = () => {
  document.documentElement.dataset.scroll = window.scrollY;
}

// $(function () {
//     $("$nav-collapse").click(function (event) {
//         var clickover = $(event.target);
//         var _opened = $(".navbar-collapse").hasClass("navbar-collapse collapse show");
//         if (_opened === true && !clickover.hasClass("navbar-toggler")) {
//             // $("button.navbar-toggler").click();
//             $("button.navbar-toggler").removeAttribute("onclick");
//         }
//     });
// });
// document.getElementById('nav-collapse').removeAttribute("onclick");

// $(function() {
//   $("$nav-collapse").click(function (event) {
//     $('.navbar-collapse').addClass('show');
//   });
// });

// var element = document.getElementById("nav-collapse");
// element.classList.add("show");

// Listen for new scroll events, here we debounce our `storeScroll` function
document.addEventListener('scroll', debounce(storeScroll), { passive: true });

// Update scroll position for first time
storeScroll();

</script>


<style>

.btn-primary {
    margin: 0 !important;
    border-radius: 35px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    padding: 14px 29px !important;
    color: #fff !important;
    border: 1px solid #70d3ff !important;
    background-color: #70d3ff !important;
    -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
}
.btn-primary:hover {
    color: #70d3ff !important;
    background-color: #fff !important;
    border-color: #70d3ff !important;
    outline: 0 !important;
    -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
}
.btn-secondary {
    border-radius: .25rem !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    padding: 14px 29px !important;
}
.btn-light {
    margin: 0 !important;
    border-radius: .25rem !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    padding: 14px 29px !important;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
}
.user-select {
    top: 0 !important;
}
.navbar-nav {
    align-items: center;
}
.user-select ul.navbar-nav.ml-auto li.nav-item.dropdown a {
    padding-top: 0;
    padding-bottom: 0;
}
/* .btn:hover .btn:focus {
    -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
} */

/* input{
    border-radius: .25rem !important;
} */
/* footer {
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
} */
.dropdown-menu.logo-menu.profile-menu {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 0px;
    border-radius: 5px;
}
.user-select ul.navbar-nav.ml-auto li.nav-item.dropdown .dropdown-menu.logo-menu.profile-menu a {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 17px;
    display: flex;
    align-items: center; 
}
.user-select ul.navbar-nav.ml-auto li.nav-item.dropdown .dropdown-menu.logo-menu.profile-menu a span {
    display: block;
    width: 40px;
    font-size: 20px;
}
.user-select ul.navbar-nav.ml-auto li.nav-item.dropdown .dropdown-menu.logo-menu.profile-menu a:nth-last-child(1) {
    border-top: 1px solid lightgray;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
}

@media (min-width: 992px) {
    div.dropdown-menu.logo-menu.profile-menu {
        min-width: 18rem;
    }
}
.user-select ul.navbar-nav.ml-auto li.nav-item.dropdown .dropdown-menu.logo-menu.profile-menu a:nth-child(1) span {
   font-size: 35px;
   margin-right: 10px;
}
.user-select ul.navbar-nav.ml-auto li.nav-item.dropdown .dropdown-menu.logo-menu.profile-menu a:nth-child(1) p {
    display: flex;
    flex-direction: column;
    white-space: initial;
    width: calc(100% - 65px);
}
.user-select ul.navbar-nav.ml-auto li.nav-item.dropdown .dropdown-menu.logo-menu.profile-menu a:nth-child(1) small {
    color: #70d3ff;
}
</style>

<style type="text/css" scoped>
    .logo-menu.profile-menu.for.mobile {
        display: none;
    }
    .header_list li:before{display: none;}
    .hd-wrap span {
        color: #fff;
    }
    .hd-wrap span:after {
        opacity: 1;
    }
    .hd-box li {
        display: block;
        padding: 0 !important;
        margin: 0 !important;
    }

    .hd-box {
        padding: 0;
        background: #363f4d;
        border-radius: 0 !important;
        border: 1px solid #fff;
    }
    .hd-box li:before {
        content: none !important;
    }
    .register-box {
        right: 0 !important;
        left: auto !important;
        top: 40px !important;
        transform: none !important;
    }
    .hd-box li button, .hd-box li a {
        padding: 5px 10px;
        display: block;
        transition: all 0.3s ease 0s;
        color: #fff;
        width: 100%;
        font-size: 16px;
        text-align: left;
        font-weight: normal;
    }
    .hd-box li + li {
        border-top: 1px solid #fff;
    }
    .hd-box li button:hover, .hd-box li a:hover {
        color: #fff;
        background: #70d3ff;
    }
    .profile-img{
        height: 45px;
        width: 45px;
        box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)!important;
        border: 2px #fff solid;
    }
      input:placeholder-shown {
        border: 5px solid red;
    }
    .header_list > li a i{position:relative;}
    .badge-danger {
        color: #fff;
        background-color: #dc3545;
        position: absolute;
        top: -4px;
        left: 2px;
        border-radius: 50%;
        width: 17px;
        height: 17px;
        line-height: 17px;
        padding: 0;
        font-size: 10px;
    }
    .user-select {
        position: relative;
        display: inline-block;
    }
    .user-select-title img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
    .user-select-title {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .user-select-title span {
        color: #fff;
        padding-left: 10px;
    }
    .user-select-title:after {
        content: "\f141";
        font-family: FontAwesome;
        /* color: #fff; */
        color: #a39b9b;
        padding-left: 10px;
    }
    .user-select-box {
        background: #363f4d;
        position: absolute;
        top: 42px;
        z-index: 99;
        opacity: 0;
        line-height: normal;
        text-align: left;
        border: 1px solid #fff;
        min-width: 210px;
        right: 0;
        visibility: hidden;
    }
    .user-select-box li + li {
        border-top: 1px solid #fff;
    }
    ul.user-select-box a:hover {
        background: #70d3ff;
    }
    ul.user-select-box #logout:hover {
        background: #70d3ff;
    }
    ul.user-select-box a {
        padding: 5px 10px;
        display: block;
        color: #fff;
    }
    ul.user-select-box #logout {
        padding: 5px 10px;
        display: block;
        color: #fff;
        width: 208px;
        text-align: left;
    }
    ul.user-select-box.box-active {
        opacity: 1;
        visibility: visible;
    }

    .user-select .dropdown-toggle::after {
        display: none;
    }
    .logo-header a {
        font-size: 24px !important;
    }

    .close-header-title .header_list {
        display: flex;
        align-items: center;
    }
    .search_new {
        width: 100%;
        border-radius: 5px;
        padding: 13px 54px 13px 15px;
        color: #000;
        font-weight: 300;
        box-shadow: 0px 3px 10px 0px rgb(128 126 126 / 20%);
        background-color: #fff;
    }
    .search_new::placeholder {
        color: #000;
    }
    .search_form {
        position: relative;
    }
    .search_button {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        color: #1f89e5;
        padding: 13px 20px;
        border-radius: 0px 5px 5px 0px;
        border-left: solid 1px rgb(234 231 231 / 50%);
    }
    .hd-wrap span#LoginDropdown:after {
        content: none;
    }
    .hd-wrap span#RegisterDropdown:after {
        content: none;
    }

    .header_list.close-header-title li:before {
        content: none;
    }
    .header_list.close-header-title li {
        padding: 0;
        margin: 0;
    }
    .hd-wrap span#RegisterDropdown {
        color: #fff !important;
        background-color: transparent !important;
        border: 2px solid #fff !important;
    }

    .login-dropdown {
    right: 0 !important;
        left: auto !important;
        transform: none !important;
        top: 26px !important;
    }
    .search_bar .ion-navicon,
    .search_bar #toggle-search {
        -webkit-transition: all 0.6s ease;
        -moz-transition: all 0.6s ease;
        transition: all 0.6s ease;
    }
    .search_bar .ion-navicon:hover,
    .search_bar #toggle-search:hover {
        color: #efefef;
    }
    .search_bar .ion-navicon {
        float: left;
    }
    .search_bar span {
        font-weight: 100;
        margin-left: 0;
    }

    #searchBar {
        width: 90%;
        border-radius: 100px;
        padding: 10px 54px 10px 15px;
        color: white;
        font-weight: 300;
        float: right;
    }

    #toggle-search {
        float: right;
    }
    i#toggle-search {
        position: relative;
        top: -1px;
        right: 30px;
        color: #70d3ff !important;
        font-size: 20px;
        line-height: 44px;
        cursor: pointer;
        
    }
    html .md-form .form-control.StripeElement {
        padding: 12px 15px !important;
    }
    html body input.InputElement.is-complete.Input {
        letter-spacing: 0.6px;
        font-size: 15px;
    }
    .search_bar{display: none;}


    @media only screen and (max-width: 991px) {
        .logo-menu.profile-menu.for.mobile a.dropdown-item p small {
            display: block;
            color: #70d3ff;
        }
        .navbar-collapse.collapse {
            transition: all 0.5s;
            max-height: 0;
            overflow: hidden;
        }

        .navbar-collapse.collapse.show {
            max-height: 500px;
            transition: all 0.5s;
        }
        .header-top .container {
            max-width: 100%;
        }
        .hd-wrap span {
            font-size: 13px;
        }
        .cart-icon img {
            width: 24px;
        }

        .cart-icon span {
            font-size: 9px;
            width: 16px;
            height: 16px;
            line-height: 16px;
        }
        .cart-icon p {
            color:black;
            font-size: 9px;
            line-height: 16px;
        }

        .user-select-title span {
            font-size: 13px;
        }
        .header-search {
            padding-left: 15px;
        }
    }

    .user-select:hover .user-select-box {
        opacity: 1;
        visibility: visible;
    }
    .header-btm.close-header-title {
        border-top: none !important;
    }

    .toprow{
        display: flex; /* Standard syntax */
        display: -webkit-flex; /* Safari */    
    }
    .topleft{
        -webkit-flex: 1; /* Safari */
        -ms-flex: 1; /* IE 10 */
        flex: 1; /* Standard syntax */
    }
    .topcenter{
        -webkit-flex: 2; /* Safari */
        -ms-flex: 2; /* IE 10 */
        flex: 2; /* Standard syntax */
    }
    .topright{
        -webkit-flex: 1; /* Safari */
        -ms-flex: 1; /* IE 10 */
        flex: 1; /* Standard syntax */
    }
    @media (max-width: 1199px) and (min-width: 654px) {
        .topright{
            -webkit-flex: 2; /* Safari */
            -ms-flex: 2; /* IE 10 */
            flex: 2; /* Standard syntax */
        }
    }
    @media (max-width: 653px) and (min-width: 528px) {
        .topright{
            -webkit-flex: 3; /* Safari */
            -ms-flex: 3; /* IE 10 */
            flex: 3; /* Standard syntax */
        }
    }
    @media (max-width: 991px) {
        .logreg {
            position: absolute;
            top: 18px;
            right: 20px;
        }
        .loggedin {
            position: absolute;
            top: 0px;
            right: 60px;
        }
        a.loggedincart{
            position: absolute;
            top: 25px;
            right: 120px;
        }
        .navbar-nav .nav-item {
            width: 100% !important;
        }
        /* a.logregcart{
            position: absolute;
            top: 25px;
            right: 220px;
        } */
    }
    @media (min-width: 992px) {
        .logreg {
            width: auto;
        }
        .loggedin {
            width:100px;
        }
        a.cart-icon {
            margin-left: 20px;
        }
    }
    @media (max-width: 527px) {
        .topright{
            -webkit-flex: 4; /* Safari */
            -ms-flex: 4; /* IE 10 */
            flex: 4; /* Standard syntax */
        }
        .topcenter{
            -webkit-flex: 0; /* Safari */
            -ms-flex: 0; /* IE 10 */
            flex: 0; /* Standard syntax */
        }
        /*.search_bar_d:hover {
            position: absolute;
            top: -23px;
            width: 300px;
            z-index: 11;
            transition: left 0.5s ease;
        }*/
        /* .search_new {
            width: 130px !important;
            -webkit-transition: width 0.4s ease-in-out !important;
            transition: width 0.4s ease-in-out !important;
        }
        .search_new:focus {
            width: 200px !important;
        } */
        /* .search_bar_d:hover {
            position: absolute;
            top: -20px;
            z-index: 1;
        } */
        /* input.search_new {
            outline: none;
        }
        input.search_new {
            -webkit-appearance: textfield;
            -webkit-box-sizing: content-box;
            font-family: inherit;
            font-size: 100%;
        }
        input.search_new::-webkit-search-decoration,
        input.search_new::-webkit-search-cancel-button {
            display: none; 
        }
        input.search_new {
            background: #ededed url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
            border: solid 1px #ccc;
            padding: 9px 10px 9px 32px;
            width: 55px;
            
            -webkit-border-radius: 10em;
            -moz-border-radius: 10em;
            border-radius: 10em;
            
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            transition: all .5s;
        }
        input.search_new:focus {
            width: 130px;
            background-color: #fff;
            border-color: #5cc0ff;
            -webkit-box-shadow: 0 0 5px rgba(38,171,255,.5);
            -moz-box-shadow: 0 0 5px rgba(38,171,255,.5);
            box-shadow: 0 0 5px rgba(38,171,255,.5);
        }
        input.search_new:-moz-placeholder {
            color: #999;
        }
        input.search_new::-webkit-input-placeholder {
            color: #999;
        }
        input[type=search].search_new {
            width: 15px;
            padding-left: 10px;
            color: transparent;
            cursor: pointer;
        }
        input[type=search].search_new:hover {
            background-color: #fff;
        }
        input[type=search].search_new:focus {
            width: 130px;
            padding-left: 32px;
            color: #000;
            background-color: #fff;
            cursor: auto;
        }
        input.search_new:-moz-placeholder {
            color: transparent;
        }
        input.search_new::-webkit-input-placeholder {
            color: transparent;
        } */
        
    }
        
        
    @media (max-width: 991px) {
        .topleft{
            margin-left: 15px;
        }
        .topright{
            margin-right: 15px;
        }
    }

    /* Header class starts */
    header {
        width: 100%;
        min-height: 3em;
        background-color: transparent;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        box-sizing: border-box;
        padding: 1em;
        transition: background-color 0.25s;
    }
    

    html[data-scroll="0"] header.banner li.afterLogin a i{
        color: #fff !important;
    }
    html[data-scroll="0"] header.banner li.afterLogin a span{
        color: #fff !important;
    }
    .cart-icon:hover {
        cursor: pointer;
    }
    .cart-icon span {
        background-color: #70d3ff;
        top: -8px !important;;
        right: 0px !important;;
        border: 1px solid white !important;
    }
    .hd-wrap span#RegisterDropdown {
        color: #fff !important;
        background-color: transparent !important;
        border: 2px solid #fff !important;
        font-size: 13px;
        padding: 13px 22px;
        line-height: 1;
        border-radius: 10rem;
        transition: all .2s ease-in-out;
        margin: 0;
        margin-left: 8px;
        font-weight: 400;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
        cursor: pointer;
    }
    .hd-wrap span#RegisterDropdown:hover, .hd-wrap span#RegisterDropdown:focus {
        -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
        box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
    }
    .hd-wrap #LoginDropdown {
        color: #fff;
        font-weight: 400;
        -webkit-transition: .35s;
        transition: .35s;
    }
    .hd-wrap #LoginDropdown:hover {
        color: #eee;
    }
    span#RegisterDropdown span {
        color: #fff !important;
    }
    span#LoginDropdown span {
        color: #fff !important;
        -webkit-transition: .35s;
        transition: .35s;
    }
    span#LoginDropdown span:hover {
        color: #eee !important;
    }
    html[data-scroll="0"] header.banner a.navbar-brand img {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
    html[data-scroll="0"] header.banner button.navbar-toggler {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
    html[data-scroll="0"] header.banner #nav-collapse ul li a span {
        /* color: #fff !important; */
        color: #343a40!important;
    }
    html[data-scroll="0"] header.banner #nav-collapse ul li a i {
        /* color: #fff; */
    }
    html[data-scroll="0"] header.banner a.cart-icon img {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
    html:not([data-scroll="0"]) body {
        padding-top: 3em;
    }
    html:not([data-scroll="0"]) header {
        position: fixed;
        top: 0;
        z-index: 2;
    }
    html:not([data-scroll="0"]) header .header-top {
        background-color: #fff;
        box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    }
    html:not([data-scroll="0"]) .hd-wrap span#RegisterDropdown {
        color: #42aaff !important;
        background-color: transparent !important;
        border: 2px solid #42aaff !important;
        font-size: 13px;
        padding: 13px 22px;
        line-height: 1;
        border-radius: 10rem;
        transition: all .2s ease-in-out;
        margin: 0;
        margin-left: 8px;
        font-weight: 400;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
        cursor: pointer;
    }
    html:not([data-scroll="0"]) .hd-wrap #LoginDropdown {
        color: #6c757d;
        font-weight: 400;
        -webkit-transition: .35s;
        transition: .35s;
    }
    html:not([data-scroll="0"]) span#RegisterDropdown span {
        color: #42aaff !important;
    }

    html:not([data-scroll="0"]) span#LoginDropdown span {
        color: #6c757d !important;
    }
    html:not([data-scroll="0"]) body {
        padding-top: 3em;
    }
    html[data-scroll="0"] header.nobanner {
        position: sticky;
        top: 0;
        z-index: 2;
    }

    html[data-scroll="0"] header.nobanner .header-top {
        background-color: #fff;
        /* box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5); */
    }
    html[data-scroll="0"] header.nobanner .hd-wrap span#RegisterDropdown {
        color: #42aaff !important;
        background-color: transparent !important;
        border: 2px solid #42aaff !important;
        font-size: 13px;
        padding: 13px 22px;
        line-height: 1;
        border-radius: 10rem;
        transition: all .2s ease-in-out;
        margin: 0;
        margin-left: 8px;
        font-weight: 400;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
        cursor: pointer;
    }
    html[data-scroll="0"] header.nobanner span#RegisterDropdown span {
        color: #42aaff !important;
    }
    .navbar .navbar-collapse.collapse.show {
        background-color: rgb(255, 255, 255);
        margin-right: -31px;
        margin-left: -31px;
        padding: 0px 25px;
    }
    html[data-scroll="0"] header.nobanner .hd-wrap #LoginDropdown {
        color: #6c757d;
        font-weight: 400;
        -webkit-transition: .35s;
        transition: .35s;
    }
    html[data-scroll="0"] header.nobanner span#LoginDropdown span {
        color: #6c757d !important;
    }

    .vue-suggestion {
        position: relative;
        width: 100%;
        border-radius: 5px;
        padding: 0;
        color: #000;
        font-weight: 300;
        box-shadow: 0px 3px 10px 0px rgb(128 126 126 / 20%);
        background-color: #fff;
        /* border: 1px solid #f4f3f3; */
        border: 1px solid #00bff3;
        box-shadow: none !important;
    }

    /* li.nav-item.search-item .suggestion-cross i {
        right: 20px !important;
        color: rgb(238, 238, 238) !important;
    } */
    .suggestion-cross i {
        right: 15px !important;
        color: rgb(238, 238, 238) !important;
        font-size: 16px;
    }
    .vue-suggestion .vs__list,
    .vue-suggestion .vs__loading {
    position: absolute;
    }
    .vue-suggestion .vs__list .vs__list-item {
    cursor: pointer;
    width:100%;
    }
    .vue-suggestion .vs__list .vs__list-item.vs__item-active {
    background-color: #f3f6fa;
    }
    .vue-suggestion .vs__selected  input.vs__input {
        width: 100% !important;
    }
    .search-item {
        margin-left: 24px;
        width: 430px;
    }

    .cart-mobile-view {
        display: none;
    }
    .cart-web-view {
        display: block;
    }
    div#nav-collapse ul.navbar-nav li.nav-item.cart-web-view .user-select ul.navbar-nav .dropdown-menu {
    padding: 0;
    top: 35px;
    }

    div#nav-collapse ul.navbar-nav li.nav-item.cart-web-view .user-select ul.navbar-nav .dropdown-menu a.cart-icon img {
        width: 20px;
        height: auto !important;
    }
    li.nav-item a.nav-link .loggedin .user-select ul.navbar-nav li.nav-item a#navbarDropdownMenuLink3 img {
        /* width: 35px;
        height: 35px; */
    }
    div#nav-collapse ul.navbar-nav li.nav-item.cart-web-view .user-select ul.navbar-nav .dropdown-menu a.cart-icon span.cartValue2 {
        left: 10px;
        font-size: 9px !important;
        width: 17px;
        height: 17px;
        line-height: 15px;
        color: #fff !important;
    }
    div#nav-collapse ul.navbar-nav li.nav-item.cart-web-view .user-select ul.navbar-nav .dropdown-menu>span {
        width: 100%;
    }

    @media (min-width:1200px) {
        .search-item {
            width: 570px;
        }
    }

    @media (max-width: 1200px) {
        .navbar-nav .nav-item:nth-child(2) {
            width: 110px;
        }
    }
    @media (max-width: 991px) {

        .cart-mobile-view {
            display: block;
            float: right;
            position: absolute;
            top: 23px !important;
            right: 85px;
        }
        .cart-web-view {
            display: none;
        }

        .vue-suggestion {
            width:100%;
        }
        .loggedin {
            position: relative !important;
            right: 0 !important;
        }
        a.loggedincart {
            position: relative  !important;
            top: 0px !important;
            right: 0px !important;
        }

        .alert-bell-icon {
            position: absolute;
            right: 55px;
            top: 26px;
        }

        .navbar-nav {
            width: 100%;
        }

        /* .search-item {
            width: 100%;
            margin-left: 0px;
            order: 2;
        } */
        .logo-menu.profile-menu.for.mobile {
            display: block;
            width: 100%;
        }
        .navbar-collapse.collapse ul.navbar-nav li.nav-item {
            display: none;
        }
        .logo-menu.profile-menu.for.mobile a.dropdown-item {
            padding-left: 15px;
            padding-right: 0;
        }
        .navbar-nav .nav-item:nth-child(1) {
            order: 3;
        }
        .navbar-nav .nav-item:nth-child(2) {
            order: 4;
        }
        .navbar-nav .nav-item:nth-child(3) {
            order: 2;
        }
        .navbar-nav .nav-item:nth-child(4) {
            order: 5;
        }
        .navbar-nav .nav-item:nth-child(5) {
            order: 1;
        }
        .logo-menu {
            top: 155px !important;
        }
        header .navbar {
            display: flex !important;
            padding-left: 0;
            padding-right: 0;
        }
        header .navbar a.navbar-brand {
            width: 22%;
            margin: 0 !important;
        }
        body .search-item {
            margin-left: 0;
            width: 55%;
        }
        header .navbar button.navbar-toggler {
            width: 23%;
            text-align: right;
            margin: 0 !important;
        }
        .cart-mobile-view .dropdown-menu.cart-menu {
            position: absolute !important;
            min-width: 13rem !important;
            right: 0px !important;
            top: 100px !important;
            width: inherit !important;
            max-width: inherit !important;
            left: inherit !important;
        }

        .cart-mobile-view .dropdown-menu.cart-menu span {width: 100%;}

        .cart-mobile-view .dropdown-menu.cart-menu span a.cart-icon.loggedincart {
            width: 100%;
            margin: 0;
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
        }

        .user-select ul.navbar-nav.ml-auto li.nav-item.dropdown a span.cartValue2 {
            width: 16px;
            left: 20px;
            top: 0px !important;
        }

        .user-select ul.navbar-nav.ml-auto li.nav-item.dropdown a img {
            width: 18px;
            height: auto;
        }
    }
    /* Header class ends */
        @media (max-width: 1130px) {
        .dropdown-menu.logo-menu.profile-menu { 
            min-width: 14rem;
        }
    }
    @media (max-width: 991px) {
        .dropdown-menu.logo-menu.profile-menu { 
            padding-top: 75px;
        }
    }
    
    @media (max-width: 600px) {
        header .navbar button.navbar-toggler {
            width: 30%;
        }
        body .search-item {
            width: 45%;
        }
    }
    @media (max-width: 480px) {
        body .search-item .vue-suggestion .vs__selected .vs__input {
            height: 28px;
            padding: 0 8px;
        }
        body .search-item {
            width: 40%;
        }
        header .navbar button.navbar-toggler {
            width: 35%;
        }
        .cart-icon img {
            width: 18px;
            height: 20px !important;
            object-fit: contain;
        }
    }
    @media (max-width: 375px) {
        .alert-bell-icon  {
            right: 45px;
            top: 18px;
        }
        .cart-mobile-view{
            top: 14px !important;
            right: 60px;
        }
    }
.user-select ul.navbar-nav.ml-auto .dropdown-menu img {
    width: 45px;
    height: 45px;
    filter: inherit !important;
    margin-right: 20px;
    }
</style>
