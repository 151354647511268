<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import { computed, watchEffect } from "@vue/runtime-core";

export default {
    name: 'app',
    components: {},
    data() {
        return {
            loadHorizontal: true,
            loadVertical: false,
        }
    }, created() {
    }, watch: {
        $route(to, from) {
            if (to.name == 'citizen') {
                this.loadHorizontal = false;
            } else {
                this.loadVertical = true;
                // import(`./public/assets/scss/style`);
                // import(`./public/assets/css/custom.css`);
            }
        }
    }, mounted() {
    }
}
</script>
<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./public/assets/scss/style";
@import "./front_public/assets/scss/style.scss";
@import "./public/assets/css/custom.css";



@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-schedule/styles/material.css";

</style>



