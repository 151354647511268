<template>
    <section class="main-view">
        <div class="container-scroller">
            <Header />
            <div class="container-fluid page-body-wrapper client-dashbboard">
                <div class="main-panel">
                    <div class="content-wrapper">
                        <router-view></router-view>
                        <!-- <div class="load-content" v-if="isLoading">
                            <vue-loaders-ball-clip-rotate color="#70D3FF" scale="2.1"></vue-loaders-ball-clip-rotate>
                        </div>
                        <div v-else>
                            <router-view></router-view>
                        </div> -->
                    </div>
                    <!-- content wrapper ends -->
                    <Footer/>
                </div>
                <!-- main panel ends -->
            </div>
            <!-- page-body-wrapper ends -->
        </div>
    </section>
</template>

<script>
import Header from "./partials/Header";
import Footer from "./partials/Footer";

export default {
    name: "guest_layout",
    components: {
        Header,
        Footer,
    }, data() {
        return {
            getPermissionsData: [],
            isLoading: true,
        };
    }, beforeMount() {
        this.getPermissions();
    }, mounted() {
        
    }, methods: {
        handleIsLoading(data) {
            this.isLoading = data;
        },
        getPermissions() {
            // this.getPermissionsData = JSON.parse(this.$session.get('userPermissions'));
            this.getPermissionsData = JSON.parse(localStorage.getItem('userPermissions'));
        },
        permissionExist(param) {
            var check = this.getPermissionsData.includes(param);
            if (check) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
