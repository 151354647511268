<template>
    <section class="app-sidebar left-sidebar">
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
                <!-- for property owner  -->
                <li class="nav-item" v-if="permissionExist('property_owner')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/Admin">
                        <i class="ti-dashboard menu-icon"></i>
                        <span class="menu-title">{{ $t('Side_bar.Dashboard') }}</span>
                    </router-link>
                </li>

                <li class="nav-item" v-if="permissionExist('property_owner')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/add/property/">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">{{ $t('Side_bar.Add_Property') }}</span>
                    </router-link>
                </li>
                <!-- <li class="nav-item" v-if="permissionExist('property_owner')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/calendar">
                        <i class="ti-calendar menu-icon"></i>
                        <span class="menu-title">Calendar</span>
                    </router-link>
                </li> -->
                <li class="nav-item" v-if="permissionExist('property_owner')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/properties/">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">{{ $t('Side_bar.Properties') }}</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="permissionExist('property_owner')">
                    <span class="nav-link" v-b-toggle="'booking-list'">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">{{ $t('Side_bar.All_Bookings') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="booking-list">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('property_owner')">
                                <router-link class="nav-link" to="/bookings">{{ $t('Side_bar.Bookings') }}</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
                <!-- for admin user  -->
                <li class="nav-item" v-if="permissionExist('super_admin')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/Admin">
                        <i class="ti-dashboard menu-icon"></i>
                        <span class="menu-title">{{ $t('Side_bar.Admin_Dashboard') }}</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="permissionExist('super_admin')">
                    <span class="nav-link" v-b-toggle="'users'">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">{{ $t('Side_bar.Users') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="users">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('super_admin')">
                                <router-link class="nav-link" to="/users/list">{{ $t('Side_bar.Users_List')
                                    }}</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
                <li class="nav-item" v-if="permissionExist('super_admin')">
                    <span class="nav-link" v-b-toggle="'properties-list'">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">{{ $t('Side_bar.Properties') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="properties-list">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('super_admin')">
                                <router-link class="nav-link" to="/properties/list">{{ $t('Side_bar.Properties_List')
                                    }}</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
                <li class="nav-item" v-if="permissionExist('super_admin')">
                    <span class="nav-link" v-b-toggle="'booking-list'">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title"> {{ $t('Side_bar.All_Bookings') }}</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="booking-list">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('super_admin')">
                                <router-link class="nav-link" to="/booking/list">{{ $t('Side_bar.Bookings')
                                    }}</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
                <li class="nav-item" v-if="permissionExist('satellite_map') || permissionExist('heat_map')">
                    <span class="nav-link" v-b-toggle="'maps'">
                        <i class="ti-map-alt menu-icon"></i>
                        <span class="menu-title">Maps</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="maps">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('satellite_map')">
                                <router-link class="nav-link" to="/satellite_map">Interactive Map</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>

                <li class="nav-item" v-if="permissionExist('spots')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/spots">
                        <i class="ti-location-pin menu-icon"></i>
                        <span class="menu-title">Spots</span>
                    </router-link>
                </li>
                <li class="nav-item"
                    v-if="permissionExist('spotters') || permissionExist('notifications') || permissionExist('welcome')">
                    <span class="nav-link" v-b-toggle="'spotters'">
                        <i class="ti-user menu-icon"></i>
                        <span class="menu-title">Spotters</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="spotters">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('spotters')">
                                <router-link class="nav-link" to="/spotter">Spotters</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('notifications')">
                                <router-link class="nav-link" to="/spotter/notifications">Notifications</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('welcome')">
                                <router-link class="nav-link" to="/spotter/welcome">Welcome</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>

                <li class="nav-item" v-if="permissionExist('app_settings')">
                    <span class="nav-link" v-b-toggle="'settings'">
                        <i class="ti-settings menu-icon"></i>
                        <span class="menu-title">Settings</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="settings">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('app_settings')">
                                <router-link class="nav-link" to="/app/settings">App Settings</router-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>

                <li class="nav-item"
                    v-if="permissionExist('categories') || permissionExist('locations') || permissionExist('spot_type') || permissionExist('questions')">
                    <span class="nav-link" v-b-toggle="'data_collections'">
                        <i class="ti-layers-alt menu-icon"></i>
                        <span class="menu-title">Data Collection</span>
                        <i class="menu-arrow"></i>
                    </span>
                    <b-collapse accordion="sidebar-accordion" id="data_collections">
                        <ul class="nav flex-column sub-menu">
                            <li class="nav-item" v-if="permissionExist('categories')">
                                <router-link class="nav-link" to="/categories">Categories</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('locations')">
                                <router-link class="nav-link" to="/locations">Locations</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('spot_type')">
                                <router-link class="nav-link" to="/spot/types">Spot Type</router-link>
                            </li>
                            <li class="nav-item" v-if="permissionExist('questions')">
                                <router-link class="nav-link" to="/data/collection/questions">Data Collection
                                    Questions</router-link>
                            </li>

                        </ul>
                    </b-collapse>
                </li>

                <li class="nav-item" v-if="permissionExist('data_export')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/">
                        <i class="ti-export menu-icon"></i>
                        <span class="menu-title">Data Export</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="permissionExist('data_import')" v-on:click="collapseAll">
                    <router-link class="nav-link" to="/">
                        <i class="ti-import menu-icon"></i>
                        <span class="menu-title">Data Import</span>
                    </router-link>
                </li>
            </ul>
        </nav>
    </section>
</template>

<script>
export default {
    name: "sidebar",
    data() {
        return {
            collapses: [{ show: false }, { show: false }, { show: false }],
            getPermissionsData: [],
        };
    },
    methods: {
        getPermissions() {
            // this.getPermissionsData = JSON.parse(this.$session.get('userPermissions'));
            this.getPermissionsData = JSON.parse(localStorage.getItem('userPermissions'));
        },
        permissionExist(param) {
            var check = this.getPermissionsData.includes(param);
            if (check) {
                return true;
            } else {
                return false;
            }
        },
        collapseAll() {
            var exp_elm = document.getElementsByClassName("show");
            if (exp_elm.length > 0) {
                var elm_id = exp_elm[0].id;
                this.$root.$emit("bv::toggle::collapse", elm_id);
            }
        }
    },
    mounted() {

        const body = document.querySelector("body");
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
            el.addEventListener("mouseover", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.add("hover-open");
                }
            });
            el.addEventListener("mouseout", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.remove("hover-open");
                }
            });
        });


    }, beforeMount() {
        this.getPermissions();
    },
    watch: {
        $route() {
            document.querySelector("#sidebar").classList.toggle("active");
        }
    }
};
</script>
<style>
.sidebar {
    border-right: 1px solid #bcbcbf69;
}
</style>