import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSnotify from 'vue-snotify'
import VueSweetalert2 from 'vue-sweetalert2'
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import './session'
import 'vue-select/dist/vue-select.css';
import InputColorPicker from "vue-native-color-picker";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import ToggleButton from 'vue-js-toggle-button'
import store from './store'
Vue.prototype.$server_url = 'https://staging.cheapcomforts.com/';
Vue.prototype.$EventBus = new Vue();
import VueLoadmore from 'vuejs-loadmore';
import VueI18n from 'vue-i18n'
import messages from '../src/languages/languages.json';
import VueAxios from 'vue-axios'
import VueSocialauth from "vue-social-auth";
import axios from 'axios'
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';


Vue.use(VueLoadmore, {
  lang: 'en-US'
});
Vue.use(VueAxios, axios)
Vue.use(VueSocialauth, {
  providers: {
    facebook: {
      clientId: "1981433512311522",
      redirectUri: "https://staging.cheapcomforts.com/login/facebook/callback",
    },
    google: {
      clientId:
        "83042922384-lbuo93ujv0v84l1ul4bln1nbdl9e81eo.apps.googleusercontent.com",
      redirectUri: "https://staging.cheapcomforts.com/login/google/callback",
    },
  },
});
Vue.use(ToggleButton)
Vue.use(VueLoaders);
Vue.use(VueSnotify)
Vue.use(InputColorPicker);
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(VueI18n)
Vue.use(router)
Vue.use(store)
Vue.use(VueGoodTablePlugin);
Vue.config.productionTip = false
Vue.component('v-select', vSelect)
const i18n = new VueI18n({
  locale: 'en',
  messages,
});
Vue.prototype.$EventBus.$on('changeLanguage', (language) => {
  i18n.locale = language;
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


