<template>
    <div>
        <link rel="icon" type="image/png" sizes="32x32" href="../../public/assets/images/favicon.png">
        <link rel="stylesheet" href="../../../public/vendor/customer/css/animate.css">
        <link rel="stylesheet" href="../../../public/vendor/customer/css/animate.css" type="text/css">
        <link href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900"
            rel="stylesheet">
        <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" type="text/css"
            rel="stylesheet">
        <link href="../../../public/vendor/customer/css/ionicons.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/owl.carousel.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/owl.theme.default.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/magnific-popup.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/bootstrap.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/jquery-ui.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/mCustomScrollbar.min.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/select2.min.css" type="text/css" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Nunito:200,600" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/style.css" type="text/css" rel="stylesheet">
        <link href="../../../public/vendor/customer/css/responsive.css" type="text/css" rel="stylesheet">
        <div>
            <header v-bind:class="headerclass" class="new-header">
                <div class="header-top before_login_search">
                    <div class="top-head">
                        <div class="container">
                            <b-navbar toggleable="lg">
                                <b-navbar-brand href="/">
                                    <img src="../../public/assets/images/cheapcomfort-logo.svg" alt="cheapcomfort">
                                </b-navbar-brand>
                                <div class="languages-icons" id="nav-collapse" is-nav>
                                    <b-navbar-nav>
                                        <b-nav-item>
                                            <div class="icon-list-info" style="display:inline-block;">
                                                <router-link v-b-modal.modal-2 to="/">
                                                    <span class="langiage-btn">
                                                        <img :src="selectedFlagIcon" alt="Selected Language">
                                                        <!-- {{ selectedLanguage }} -->
                                                    </span>
                                                </router-link>
                                            </div>
                                        </b-nav-item>
                                        <b-nav-item>
                                            <div class="icon-list-info" style="display:inline-block;">
                                                <router-link v-b-modal.modal-3 to="/" style="text-decoration: none;">
                                                    <span class="langiage-btn">
                                                        <span
                                                            style="padding: 0px; color: black;font-size: 16px;margin-bottom: 0px;">{{
                                                                currentcurrency ? currentcurrency : 'USD'
                                                            }}</span>
                                                    </span>
                                                </router-link>
                                            </div>
                                        </b-nav-item>
                                        <b-nav-item>
                                            <div class="icon-list-info icon-list-info-star"
                                                style="display:inline-block;">
                                                <span v-if="!hideHeader" @click="toggleFavorite">
                                                    <i :class="['fa', isFavorited ? 'fa-star' : 'fa-star-o']"
                                                        aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <b-dropdown v-if="favoriteProperties.length" ref="dropdown" right>
                                                <template v-slot:button-content>
                                                    <img :src="favoriteProperties[0].photos[0].path"
                                                        alt="Property Image" width="50" />
                                                </template>
                                                <b-dropdown-item v-for="property in favoriteProperties"
                                                    :key="property.id">
                                                    <img :src="property.photos[0].path" alt="Property Image"
                                                        width="50" />
                                                    <span>{{ property.city }}, {{ property.state }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-nav-item>
                                        <b-nav-item v-if="!hidehomepage">
                                            <div class="icon-list-info" style="display:inline-block;">
                                                <router-link to="/">
                                                    <span>
                                                        <i class="fa fa-home" aria-hidden="true"></i>
                                                    </span>
                                                </router-link>
                                            </div>
                                        </b-nav-item>

                                    </b-navbar-nav>
                                </div>
                                <div class="center-tabs-layout" id='main_bar' v-if="!hideHeader">
                                    <b-card no-body>
                                        <b-tabs pills>
                                            <b-tab id="__BVID__15" @click="handleTabClick">

                                                <template #title>
                                                    <div class="title-content">
                                                        <div class="title-col"><i class="fa fa-map-marker"
                                                                aria-hidden="true"></i></div>
                                                        <div class="title-col">
                                                            <small>{{ $t('property_detail.Destination') }}</small>
                                                            <p>
                                                                {{ searchQuery ?? (city && regionName && country ?
                                                                    `${city},
                                                                ${regionName}, ${country}` : '') }}
                                                            </p>
                                                            <i v-if="searchQuery"
                                                                class="fa fa-times-circle position-absolute"
                                                                @click="clearSearch"
                                                                style="top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"></i>
                                                        </div>
                                                    </div>
                                                    <div class="search-box-header d-flex align-items-center">
                                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                                        <div class="position-relative" style="width: 100%;">
                                                            <b-form-input id="type-search" type="text" v-model="query"
                                                                style="outline: none; border: 0;" class="form-control"
                                                                autocomplete="off"
                                                                :placeholder="`${city || ''} ${regionName || ''} ${country || ''}`"
                                                                @keyup="handleKeyUp" @input="onInput"></b-form-input>
                                                            <i v-if="searchQuery"
                                                                class="fa fa-times-circle position-absolute"
                                                                @click="clearSearch"
                                                                style="top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"></i>
                                                            <ul v-if="suggestions.length > 0" class="suggestions-list">
                                                                <li v-for="(suggestion, index) in suggestions"
                                                                    :key="index" @click="selectSuggestion(suggestion)">
                                                                    {{ suggestion.description }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </template>
                                                <!------------->
                                                <b-card-text>
                                                    <div>
                                                        <div class="pb-4 related-filter">
                                                            <div href="/" @click="exploreNearby"
                                                                class="tabs-content pt-0">
                                                                <div class="content-col">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" fill="none">
                                                                        <path fill="#676767"
                                                                            d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333m0 12A5.34 5.34 0 0 1 2.667 8 5.34 5.34 0 0 1 8 2.667 5.34 5.34 0 0 1 13.333 8 5.34 5.34 0 0 1 8 13.333m-3.667-1.666L9.34 9.34l2.327-5.007L6.66 6.66zM8 7.267c.407 0 .733.326.733.733A.73.73 0 0 1 8 8.733.73.73 0 0 1 7.267 8c0-.407.326-.733.733-.733">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                                <div class="content-col">
                                                                    <p>Explore nearby</p>
                                                                    <small>Find accommodations near you</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!------------->
                                                        <div>
                                                            <h4>Related</h4>
                                                            <div v-for="(city, index) in this.cities" :key="index"
                                                                class="related-filter">
                                                                <div href="/" class="tabs-content"
                                                                    @click="$root.$emit('load-items', city.city, city.country, '', s1date, e1date, quantity); updateSearchPlaceholder(city.city, city.country, city.state)">
                                                                    <div class="content-col">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            width="16" height="16" fill="none">
                                                                            <path fill="#676767"
                                                                                d="M8 1C5.236 1 3 3.191 3 5.9 3 9.575 8 15 8 15s5-5.425 5-9.1C13 3.191 10.764 1 8 1M4.429 5.9c0-1.932 1.6-3.5 3.571-3.5s3.571 1.568 3.571 3.5c0 2.016-2.057 5.033-3.571 6.916C6.514 10.947 4.429 7.895 4.429 5.9">
                                                                            </path>
                                                                            <path fill="#676767"
                                                                                d="M8 7.65c.986 0 1.786-.784 1.786-1.75S8.986 4.15 8 4.15s-1.786.784-1.786 1.75.8 1.75 1.786 1.75">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                    <div class="content-col">
                                                                        <p>{{ city.city }}</p>
                                                                        <small>{{ city.country }}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h4 style=" margin-top: 10%;">Recent</h4>
                                                            <div>
                                                                <a href="/" class="tabs-content">
                                                                    <div class="content-col">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            width="16" height="16" fill="none">
                                                                            <path fill="#676767"
                                                                                d="M8 1C5.236 1 3 3.191 3 5.9 3 9.575 8 15 8 15s5-5.425 5-9.1C13 3.191 10.764 1 8 1M4.429 5.9c0-1.932 1.6-3.5 3.571-3.5s3.571 1.568 3.571 3.5c0 2.016-2.057 5.033-3.571 6.916C6.514 10.947 4.429 7.895 4.429 5.9">
                                                                            </path>
                                                                            <path fill="#676767"
                                                                                d="M8 7.65c.986 0 1.786-.784 1.786-1.75S8.986 4.15 8 4.15s-1.786.784-1.786 1.75.8 1.75 1.786 1.75">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                    <div class="content-col">
                                                                        <p>{{ city }}</p>
                                                                        <small>{{ country }}</small>
                                                                    </div>
                                                                </a>
                                                            </div>

                                                        </div>
                                                        <!------------->
                                                    </div>
                                                    <div>

                                                        <div class="related-filter">
                                                            <h4>Popular</h4>
                                                            <div href="/" class="tabs-content"
                                                                @click="$root.$emit('load-items', 'New Hanna', 'USA', 'Maryland', s1date, e1date, quantity); updateSearchPlaceholder('New Hanna', 'USA', 'Maryland',)">
                                                                <div class="content-col">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" fill="none">
                                                                        <path fill="#676767"
                                                                            d="M8 1C5.236 1 3 3.191 3 5.9 3 9.575 8 15 8 15s5-5.425 5-9.1C13 3.191 10.764 1 8 1M4.429 5.9c0-1.932 1.6-3.5 3.571-3.5s3.571 1.568 3.571 3.5c0 2.016-2.057 5.033-3.571 6.916C6.514 10.947 4.429 7.895 4.429 5.9">
                                                                        </path>
                                                                        <path fill="#676767"
                                                                            d="M8 7.65c.986 0 1.786-.784 1.786-1.75S8.986 4.15 8 4.15s-1.786.784-1.786 1.75.8 1.75 1.786 1.75">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                                <div class="content-col">
                                                                    <p>New Hanna </p>
                                                                    <small>Maryland, USA</small>
                                                                </div>
                                                            </div>
                                                            <div href="/" class="tabs-content"
                                                                @click="$root.$emit('load-items', 'California', 'America', 'CA', s1date, e1date, quantity); updateSearchPlaceholder('California', 'America', 'CA')">
                                                                <div class="content-col">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" fill="none">
                                                                        <path fill="#676767"
                                                                            d="M8 1C5.236 1 3 3.191 3 5.9 3 9.575 8 15 8 15s5-5.425 5-9.1C13 3.191 10.764 1 8 1M4.429 5.9c0-1.932 1.6-3.5 3.571-3.5s3.571 1.568 3.571 3.5c0 2.016-2.057 5.033-3.571 6.916C6.514 10.947 4.429 7.895 4.429 5.9">
                                                                        </path>
                                                                        <path fill="#676767"
                                                                            d="M8 7.65c.986 0 1.786-.784 1.786-1.75S8.986 4.15 8 4.15s-1.786.784-1.786 1.75.8 1.75 1.786 1.75">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                                <div class="content-col">
                                                                    <p>California</p>
                                                                    <small>CA, America</small>
                                                                </div>
                                                            </div>
                                                            <div href="/" class="tabs-content"
                                                                @click="$root.$emit('load-items', 'Port Otto', 'Austria', 'Florida', s1date, e1date, quantity); updateSearchPlaceholder('Port Otto', 'Austria', 'Florida')">
                                                                <div class="content-col">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" fill="none">
                                                                        <path fill="#676767"
                                                                            d="M8 1C5.236 1 3 3.191 3 5.9 3 9.575 8 15 8 15s5-5.425 5-9.1C13 3.191 10.764 1 8 1M4.429 5.9c0-1.932 1.6-3.5 3.571-3.5s3.571 1.568 3.571 3.5c0 2.016-2.057 5.033-3.571 6.916C6.514 10.947 4.429 7.895 4.429 5.9">
                                                                        </path>
                                                                        <path fill="#676767"
                                                                            d="M8 7.65c.986 0 1.786-.784 1.786-1.75S8.986 4.15 8 4.15s-1.786.784-1.786 1.75.8 1.75 1.786 1.75">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                                <div class="content-col">
                                                                    <p>Port Otto</p>
                                                                    <small>Florida, Austria</small>
                                                                </div>
                                                            </div>
                                                            <div href="/" class="tabs-content"
                                                                @click="$root.$emit('load-items', 'Baltimore', 'America', 'Maryland', s1date, e1date, quantity); updateSearchPlaceholder('Baltimore', 'America', 'Maryland')">
                                                                <div class="content-col">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" fill="none">
                                                                        <path fill="#676767"
                                                                            d="M8 1C5.236 1 3 3.191 3 5.9 3 9.575 8 15 8 15s5-5.425 5-9.1C13 3.191 10.764 1 8 1M4.429 5.9c0-1.932 1.6-3.5 3.571-3.5s3.571 1.568 3.571 3.5c0 2.016-2.057 5.033-3.571 6.916C6.514 10.947 4.429 7.895 4.429 5.9">
                                                                        </path>
                                                                        <path fill="#676767"
                                                                            d="M8 7.65c.986 0 1.786-.784 1.786-1.75S8.986 4.15 8 4.15s-1.786.784-1.786 1.75.8 1.75 1.786 1.75">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                                <div class="content-col">
                                                                    <p>Baltimore</p>
                                                                    <small>Maryland, America</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!------------->
                                                    </div>
                                                </b-card-text>
                                            </b-tab>
                                            <b-tab @click="removeClass1" id="__BVID__17">
                                                <template #title>
                                                    <div class="title-content show-title">
                                                        <div class="title-col date-picker">
                                                            <!-- <i class="fa fa-calendar-o" aria-hidden="true"></i> -->
                                                            <div class="date-picker-wrapper">
                                                                <date-range-picker v-model="dateRange" :min-date="today"
                                                                    ref="datePickerRef" :locale-data="customLocale"
                                                                    class="date-picker-trigger" />
                                                                <span class="calendar-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512" class="icon">
                                                                        <path
                                                                            d="M96 32l0 32L48 64C21.5 64 0 85.5 0 112l0 48 448 0 0-48c0-26.5-21.5-48-48-48l-48 0 0-32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 32L160 64l0-32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192L0 192 0 464c0 26.5 21.5 48 48 48l352 0c26.5 0 48-21.5 48-48l0-272z" />
                                                                    </svg>
                                                                </span>
                                                            </div>

                                                        </div>
                                                        <div class="title-col ps-3">
                                                            <p>
                                                                <span v-if="sdate && edate">{{ sdate }} - {{ edate
                                                                    }}</span>
                                                                <span v-else> {{ $t('property_detail.Add_dates')
                                                                    }}</span>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </template>
                                                <b-card-text style="display: none !important;">
                                                    <p>dd</p>
                                                </b-card-text>
                                            </b-tab>
                                            <b-tab @click="removeClass2" id="__BVID__19">
                                                <template #title>
                                                    <div class="title-content show-title">
                                                        <div class="title-col"><i class="fa fa-user"
                                                                aria-hidden="true"></i>
                                                        </div>
                                                        <div class="title-col">
                                                            <small> {{ $t('property_detail.Guests') }}</small>
                                                            <p class="text-center">{{ quantity + childQuantity }}</p>
                                                        </div>
                                                    </div>
                                                </template>
                                                <b-card-text>
                                                    <div class="guest-content">
                                                        <div class="guest-col">
                                                            <h4>Adults</h4>
                                                        </div>
                                                        <div class="guest-col">
                                                            <template>
                                                                <div class="quantity">
                                                                    <b-input-group>
                                                                        <b-input-group-prepend>
                                                                            <b-btn variant="info"
                                                                                @click="decrement()">-</b-btn>
                                                                        </b-input-group-prepend>

                                                                        <b-form-input type="number" min="0.00"
                                                                            :value="quantity !== '' ? quantity : 0"></b-form-input>

                                                                        <b-input-group-append>
                                                                            <b-btn variant="info"
                                                                                @click="increment()">+</b-btn>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="guest-content">
                                                        <div class="guest-col">
                                                            <h4>Childs</h4>
                                                        </div>
                                                        <div class="guest-col">
                                                            <template>
                                                                <div class="quantity">
                                                                    <b-input-group>
                                                                        <b-input-group-prepend>
                                                                            <b-btn variant="info"
                                                                                @click="childDecrement()">-</b-btn>
                                                                        </b-input-group-prepend>

                                                                        <b-form-input type="number" min="0.00"
                                                                            :value="childQuantity"></b-form-input>

                                                                        <b-input-group-append>
                                                                            <b-btn variant="info"
                                                                                @click="childIncrement()">+</b-btn>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="guest-content">
                                                        <div class="guest-col">
                                                            <h4>Petsss</h4>
                                                        </div>
                                                        <div class="guest-col">
                                                            <template>
                                                                <div class="quantity">
                                                                    <b-input-group>
                                                                        <b-input-group-prepend>
                                                                            <b-btn variant="info"
                                                                                @click="petDecrement()">-</b-btn>
                                                                        </b-input-group-prepend>

                                                                        <b-form-input type="number" min="0.00"
                                                                            :value="petQuantity"></b-form-input>

                                                                        <b-input-group-append>
                                                                            <b-btn variant="info"
                                                                                @click="petIncrement()">+</b-btn>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </b-card-text>
                                            </b-tab>
                                        </b-tabs>
                                    </b-card>
                                </div>
                                <b-navbar-toggle v-if="showAfterLogin || true" target="nav-collapse1"></b-navbar-toggle>
                                <b-collapse id="nav-collapse1" is-nav>
                                    <b-navbar-nav>
                                        <b-nav-item class="login-links">
                                            <div v-bind:class="(showBeforeLogin) ? 'logreg' : 'loggedin'">
                                                <!-- <div class="hd-wrap dropdown login-link" v-if="showBeforeLogin">
                                                        <span class="dropdown-toggle" id="LoginDropdown"
                                                            v-on:click="redirectLogin('/home/signup', 'customer')">
                                                            <span class="text-white dnon_m">Sign Up </span>
                                                        </span>
                                                    </div> -->
                                                <div class="hd-wrap dropdown login-link" v-if="showBeforeLogin">
                                                    <span class="dropdown-toggle" id="LoginDropdown" v-b-modal.modal-5>
                                                        <span class="text-white dnon_m">{{ $t('register.sign_up')
                                                            }} </span>
                                                    </span>
                                                </div>
                                                <!-- <div class="hd-wrap dropdown login-link" v-if="showBeforeLogin">
                                                        <span class="dropdown-toggle" id="LoginDropdown" role="button"
                                                            v-on:click="redirectLogin('/home/login', 'customer')">
                                                            <span class="dnon_m">Log In </span>
                                                        </span>
                                                    </div> -->
                                                <div class="hd-wrap dropdown login-link" v-if="showBeforeLogin">
                                                    <span class="dropdown-toggle" id="LoginDropdown" role="button"
                                                        v-b-modal.modal-4>
                                                        <span class="dnon_m"> {{ $t('header.login') }}</span>
                                                    </span>
                                                </div>

                                            </div>
                                        </b-nav-item>
                                        <!-- <b-nav-item class="login-links" v-if="showAfterLogin">
                                                <div class="loggedin" v-show="!isAddPropertyRoute">
                                                    <div class="hd-wrap dropdown login-link">
                                                        <span class="dropdown-toggle" id="LoginDropdown" role="button"
                                                            @click="incomeStatistics()">
                                                            <span class="dnon_m">My Properties</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </b-nav-item> -->
                                        <b-nav-item class="login-links" v-if="showbooking && !isAddPropertyRoute">
                                            <div class="loggedin" v-if="showAfterLogin">
                                                <div class="hd-wrap dropdown login-link">
                                                    <span class="dropdown-toggle" id="LoginDropdown" role="button"
                                                        @click="bookings()">
                                                        <span class="dnon_m">{{ $t('property_detail.My_Bookings')
                                                            }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </b-nav-item>
                                        <b-nav-item class="login-links"
                                            v-show="!(isMyBookingsRoute || isAddPropertyRoute)">
                                            <div class="loggedin">
                                                <div class="hd-wrap dropdown login-link">
                                                    <span class="dropdown-toggle" id="LoginDropdown" role="button"
                                                        @click="Addproperty()">
                                                        <span class="dnon_m">{{ $t('Side_bar.Add_Property') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </b-nav-item>
                                        <b-nav-item class="login-links" v-if="showAfterLogin">
                                            <div class="loggedin" v-if="showManageProperties">
                                                <div class="hd-wrap dropdown login-link">
                                                    <span class="dropdown-toggle" id="LoginDropdown" role="button"
                                                        @click="manageProperties()">
                                                        <span class="dnon_m">{{
                                                            $t('manage_properties.manage_properties_title') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </b-nav-item>
                                        <b-nav-item class="login-links" v-if="showAfterLogin">
                                            <div class="loggedin" v-if="showManageProperties">
                                                <div class="hd-wrap dropdown login-link">
                                                    <span class="dropdown-toggle" id="LoginDropdown" role="button"
                                                        @click="goToDashboard()">
                                                        <span class="dnon_m">Dashboard</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </b-nav-item>
                                        <b-nav-item v-if="showAfterLogin">
                                            <b-dropdown class="profile-dropdown" variant="link" id="profile-dropdown">
                                                <!-- Dropdown toggle button with profile icon -->
                                                <template #button-content>
                                                    <div class="icon-list-info" style="display:inline-block;">
                                                        <span class="language-btn">
                                                            <img v-if="progileImage" :src="progileImage" alt="Profile"
                                                                style="width: 40px; height: 40px; border-radius: 50px;">
                                                            <img v-else :src="userProfileImg" alt="Profile"
                                                                style="width: 30px; height: 30px; border-radius: 50px;">
                                                            <span style="color: black;">{{ username.name
                                                                }}</span>
                                                        </span>
                                                    </div>
                                                </template>
                                                <!-- Dropdown menu items -->
                                                <b-dropdown-item @click="logout()">{{ $t('header.logout')
                                                    }}</b-dropdown-item>
                                            </b-dropdown>
                                        </b-nav-item>
                                    </b-navbar-nav>
                                </b-collapse>
                            </b-navbar>
                        </div>
                    </div>
                    <div class="bottom-head" v-if="!hideHeader">
                        <div class="container">
                            <div class="head-list">
                                <div class="left-head">
                                    <ul>
                                        <li>
                                            <button v-b-modal.modal-1 class="filter-btn" type="button">
                                                <div class="flex gap-x-xs">
                                                    <i class="fa fa-sliders"></i>
                                                    <span class="text-n font-medium leading-none lg:hidden">
                                                        {{ $t('property_detail.More_filters') }}
                                                    </span>
                                                    <i class="fa fa-angle-down"></i>
                                                </div>
                                            </button>
                                        </li>
                                        <li>
                                            <div class="switch-btn button-switcher-container">
                                                <button v-if="showLeftArrow" @click="scrollLeft"
                                                    class="arrow left-arrow">
                                                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                </button>
                                                <div class="button-switcher" ref="buttonSwitcher" @scroll="checkScroll">
                                                    <button class="switcher" type="button" @click="selectButton(index)"
                                                        :class="{ 'selected': index === selectedButtonIndex }"
                                                        v-show="index !== 10 && index > 2"
                                                        v-for="(button, index) in buttons" :key="index">
                                                        <div class="button-img">
                                                            <img :alt="button.alt" :src="button.src">
                                                        </div>
                                                        <span class="text-s p-xs mb-2">{{ $t(button.text) }}</span>
                                                        <div class="unerline" v-if="index === selectedButtonIndex">
                                                        </div>
                                                    </button>
                                                </div>

                                                <button v-if="showRightArrow" @click="scrollRight"
                                                    class="arrow right-arrow">
                                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="right-head">
                                        <div class="map-bg">
                                            <button class="map-btn" v-b-modal.modal-xl type="button">Show map</button>
                                        </div>
                                    </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <b-modal id="modal-5" :title="$t('register.register_title')" hide-footer ref="myModalRef">
                <div class="wrapper register-wrapper">
                    <div class="register-page">
                        <div class="container p-0">
                            <span class="back-button" v-if="currStep < 7">
                                <button id="bckBtn" name="bckBtn" class="pull-right" v-if="currStep > 1" @click="bckBtn"
                                    :disabled="regBusy">
                                    <i class="fa fa-angle-left"></i>
                                </button>
                                <button id="bckBtn" name="bckBtn" class="pull-left" v-else @click="redirect('/')">
                                    <i class="fa fa-angle-left"></i>
                                </button>
                            </span>
                            <div class="card card-pages">
                                <div class="form-title">
                                    <h4>{{ $t('Propertis.welcom') }}</h4>
                                </div>
                                <div class="card-body">
                                    <div class="row" v-if="regMsgTextErr != ''">
                                        <div class="col-md-12 form-group md-form">
                                            <div class="alert alert-danger">{{ regMsgTextErr }}</div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="regMsgTextSucc != ''">
                                        <div class="col-md-12 form-group md-form">
                                            <div class="alert alert-success">{{ regMsgTextSucc }}</div>
                                        </div>
                                    </div>

                                    <div class="form-fields" v-if="currStep == 1">
                                        <div class="form-group">
                                            <input id="regFname" name="regFname" :placeholder="$t('register.name')"
                                                type="text" class="form-control" autocomplete="off" v-model="regFname">
                                        </div>
                                        <div class="form-group">
                                            <input id="regEmail" name="regEmail" :placeholder="$t('register.email')"
                                                type="text" class="form-control" autocomplete="off" v-model="regEmail">
                                        </div>
                                        <div class="form-group">
                                            <input id="regPhone" name="regPhone" maxlength="16"
                                                @input="enforcePhoneFormat()" type="tel" placeholder="(123) 456-7890"
                                                class="form-control" autocomplete="off" v-model="regPhone">
                                        </div>
                                    </div>
                                    <div v-if="currStep == 1">
                                        <div class="form-group confrmcode" v-if="codesent">
                                            <div v-if="phoneverifed" class="emailverified">
                                                Verified successfully.
                                            </div>
                                            <label>Six Digit code send to you Mobile number please enter</label>
                                            <input id="verifycode" name="verifycode" maxlength="16" type="number"
                                                placeholder="Code" class="form-control" autocomplete="off"
                                                v-model="vcode">
                                        </div>
                                    </div>

                                    <div class="form-fields" v-if="currStep == 2">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input id="regPassword" name="regPassword" :type="type"
                                                    class="form-control" autocomplete="off" v-model="regPassword">
                                                <div class="input-group-append" @click="showPassword">
                                                    <span class="input-group-text"><i :class="btnText"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <!-- <label for="regCpassword">{{ $t('register.confirm_password') }} <span class="text-red">*</span></label> -->
                                            <input id="regCpassword" name="regCpassword" type="password"
                                                class="form-control" autocomplete="off" v-model="regCpassword">
                                        </div>
                                    </div>
                                    <div class="form-fields" v-if="currStep === 3">
                                        <div class="profile-photo-section">
                                            <div class="form-group flex-form-group"
                                                v-if="!isCameraOpen && !profilePhotoPreview">
                                                <!-- <label for="profilePhoto">Upload Profile</label> -->
                                                <!-- File upload for selecting an image -->
                                                <b-form-file id="profilePhoto" v-model="profilePhoto"
                                                    @change="onProfileChange" placeholder="Upload Profile"
                                                    drop-placeholder="Upload profile" accept="image/*"></b-form-file>

                                                <div class="or-field">
                                                    <span>OR</span>
                                                </div>
                                                <!-- Button to open camera -->
                                                <b-button variant="outline-primary" @click="openCamera">Use
                                                    Camera</b-button>
                                            </div>

                                            <!-- Display video stream if camera is open -->
                                            <div class="camera-video-sec" v-if="isCameraOpen">
                                                <video ref="cameraVideo" autoplay playsinline></video>
                                                <!-- <button @click="captureImage" class="capture-button">Capture</button> -->
                                                <button class="btn btn-primary" @click="captureImage">
                                                    <i class="fas fa-trash-alt"></i>Capture
                                                </button>
                                            </div>

                                            <!-- Display profile photo preview -->
                                            <div v-if="profilePhotoPreview" class="images-listing new-images-listing">
                                                <div class="images-card">
                                                    <div class="selected-img">
                                                        <img :src="profilePhotoPreview" alt="Profile Photo Preview"
                                                            width="200">
                                                    </div>
                                                    <button type="button" class="btn btn-primary" @click="removeImage">
                                                        <i class="fas fa-trash-alt"></i> Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-fields" v-if="currStep === 4">
                                        <vue-recaptcha sitekey="6Lc_o30qAAAAAGIPH2_7HHaS9KJsZpg3mZUoFP0-"
                                            @verify="onVerify1"></vue-recaptcha>
                                    </div>


                                    <div class="form-check">
                                        <label class="form-check-label text-muted">
                                            <input type="checkbox" name="termsAndConditions" class="form-check-input">
                                            Accept Terms
                                            and Conditions
                                            <i class="input-helper"></i>
                                        </label>
                                    </div>
                                    <div v-if="verifyphonenumber">
                                        <div class="row mt-3" v-if="currStep < 7">
                                            <div class="col-md-6 form-group md-form">
                                                <button id="regSub" name="regSub" class="btn btn-primary pull-right"
                                                    @click="regSub" :disabled="regBusy">{{ regBtnText }}</button>
                                            </div>
                                            <div class="col-md-6 form-group md-form">
                                                <button id="regSub" name="bckBtn" class="btn btn-primary pull-right"
                                                    @click="bckBtn" :disabled="regBusy"> {{ $t('register.back') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="row mt-3" v-if="currStep == 1">
                                            <div class="col-md-12 form-group md-form" v-if="!codesend">
                                                <button id="regSub" name="regSub" class="btn btn-primary pull-right"
                                                    @click="sendverifycode" :disabled="regBusy">Send Verification
                                                    Code</button>
                                            </div>
                                            <div class="col-md-12 form-group md-form" v-if="!verifycodenow">
                                                <button id="regSub" name="bckBtn" class="btn btn-primary pull-right"
                                                    @click="vrfynow" :disabled="regBusy">Verify Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="currStep == 7">
                                        <div class="col-md-12 form-group md-form">
                                            <button id="regSingIn" name="regSingIn" class="btn btn-primary pull-right"
                                                @click="regSingIn">{{ $t('register.sign_in') }}</button>
                                        </div>
                                    </div>
                                    <div class="social-login text-center">
                                        <button class="btn" @click="AuthProvider('google')"><i
                                                style="background: linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                                                class="fa fa-google" aria-hidden="true"></i> <span>
                                                {{ $t('Propertis.login_with_google') }}</span></button>
                                        <button class="btn" @click="AuthProvider('facebook')"><i style="color: #1877F2;"
                                                class="fa fa-facebook" aria-hidden="true"></i> <span>
                                                {{ $t('Propertis.login_with_Facebook') }}</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
            <b-modal id="modal-6" title="Verify Now" hide-footer ref="myModalRef">
                <div class="wrapper register-wrapper">
                    <div class="register-page">
                        <div class="container p-0">
                            <span class="back-button" v-if="currStep1 < 7">
                                <button id="bckBtn" name="bckBtn" class="pull-right" v-if="currStep > 1" @click="bckBtn"
                                    :disabled="regBusy">
                                    <i class="fa fa-angle-left"></i>
                                </button>
                                <button id="bckBtn" name="bckBtn" class="pull-left" v-else @click="redirect('/')">
                                    <i class="fa fa-angle-left"></i>
                                </button>
                            </span>
                            <div class="card card-pages">
                                <div class="card-body">
                                    <div v-if="currStep1 == 1">
                                        <div class="form-title">
                                            <h4>Please enter your email here.</h4>
                                        </div>
                                        <div class="row" v-if="regMsgTextErr1 != ''">
                                            <div class="col-md-12 form-group md-form">
                                                <div class="alert alert-danger">{{ regMsgTextErr1 }}</div>
                                            </div>
                                        </div>
                                        <div class="form-fields">
                                            <div class="form-group">
                                                <input id="regEmail1" name="regEmail1"
                                                    :placeholder="$t('register.email')" type="text" class="form-control"
                                                    autocomplete="off" v-model="regEmail1">
                                            </div>
                                        </div>
                                        <div class="row mt-3" v-if="currStep1 < 7">
                                            <div class="col-md-12 form-group md-form">
                                                <button id="regSub" name="regSub" class="btn btn-primary pull-right"
                                                    @click="verifybuton" :disabled="regBusy">Verify Now</button>
                                            </div>
                                            <div class="col-md-12 form-group md-form" v-if="notfound">
                                                <div class="social-login text-center"> <button class="btn"
                                                        @click="createaccount1()"><i class="fa fa-user m-right-5"></i>
                                                        <span>{{
                                                            $t('login.create_account') }}</span></button></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="currStep1 == 2">
                                        <div style="padding: 10px;">
                                            <span>
                                                A 6-digit code has been sent
                                                <span v-if="codesendagain">again</span> to your email.
                                                Please enter it. It will expire in
                                                <span v-if="timeLeft > 0">{{ formattedTime }}</span>
                                                <span v-else>Expired</span>.
                                            </span>
                                        </div>
                                        <div class="row" v-if="regMsgTextErr2 != ''">
                                            <div class="col-md-12 form-group md-form">
                                                <div class="alert alert-danger">{{ regMsgTextErr2 }}</div>
                                            </div>
                                        </div>
                                        <div class="form-fields">
                                            <div class="form-group">

                                                <div class="input-group">
                                                    <input id="verifycode" name="verifycode" type="number"
                                                        class="form-control" autocomplete="off" v-model="verifycode">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3" v-if="currStep1 < 7">
                                            <div class="col-md-6 form-group md-form">
                                                <button id="regSub" name="regSub" class="btn btn-primary pull-right"
                                                    @click="verifynow" :disabled="regBusy">Verify</button>
                                            </div>
                                            <div class="col-md-6 form-group md-form">
                                                <button id="regSub" name="regSub" class="btn btn-primary pull-right"
                                                    @click="sendagain" :disabled="regBusy">Send Again</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="currStep1 == 7">
                                        <div class="col-md-12 form-group md-form">
                                            <button id="regSingIn" name="regSingIn" class="btn btn-primary pull-right"
                                                @click="regSingIn">{{ $t('register.sign_in') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
            <b-modal id="modal-4" :title="$t('login.login_title')" hide-footer ref="loginModalRef">
                <div class="wrapper register-wrapper">
                    <div class="login-page">
                        <div class="container p-0">
                            <!-- <div class="main-title">
                                    <h3 class="mb-0">{{ $t('login.login_title') }}</h3>
                                </div> -->
                            <div class="card card-pages">
                                <div class="form-title">
                                    <!-- <h4>{{$t('Propertis.welcom')}}</h4> -->
                                </div>
                                <div class="card-body">
                                    <div v-if="emailverified" class="emailverified">
                                        Verified successfully.
                                    </div>
                                    <div v-if="emailnotverified" class="emailnotverified">
                                        Email not verified
                                        <a href="#" @click.prevent="verifyEmail"
                                            style="color: black; text-decoration: underline;">
                                            Verify now</a>.
                                    </div>
                                    <div v-if="showError" class="alert alert-danger msg">{{ error }}</div>
                                    <div v-if="showSuccess" class="alert alert-success msg">{{ success }}</div>

                                    <form class="form-horizontal m-t-30" action="#" method="POST"
                                        v-on:submit.prevent="login">
                                        <div class="form-fields">
                                            <div class="form-group">
                                                <div class="input-file">
                                                    <!-- <label>{{ $t('login.email') }}</label> -->
                                                    <input class="form-control" type="text"
                                                        :placeholder="$t('login.enter_email')" autofocus
                                                        v-model="email">
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-file">
                                                    <!-- <label>{{ $t('login.password') }}</label> -->
                                                    <input class="form-control" type="password"
                                                        :placeholder="$t('login.enter_password')" name="password"
                                                        autofocus v-model="password">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row my-3" style="justify-content: space-between;">
                                            <div>
                                                <div class="checkbox checkbox-primary">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customCheck1">
                                                        <label class="custom-control-label" for="customCheck1">{{
                                                            $t('login.remember_me') }}</label>
                                                    </div>
                                                </div>
                                                <vue-recaptcha sitekey="6Lc_o30qAAAAAGIPH2_7HHaS9KJsZpg3mZUoFP0-"
                                                    @verify="onVerify"></vue-recaptcha>
                                            </div>
                                        </div>

                                        <div class="form-group text-center m-t-20 mb-2">
                                            <div class="input-file">
                                                <button
                                                    class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                                                    :class="{ disabled: isDisabled }" type="submit">{{
                                                        $t('login.login_button') }}</button>
                                            </div>
                                        </div>

                                        <!-- <div class="form-group row m-t-30 m-b-0 forget-account"
                                                style="margin: 0px !important; justify-content: space-between;">
                                                <div>
                                                    <router-link to="/forgot-password" class="text-muted"><i
                                                            class="fa fa-lock m-r-5"></i> {{ $t('login.forgot_password')
                                                        }}</router-link>
                                                </div>
                                                <div class="text-right">
                                                    <router-link to="/home/signup" class="text-muted "><i
                                                            class="fa fa-user m-right-5"></i>{{ $t('login.create_account')
                                                        }}</router-link>
                                                </div>
                                            </div> -->
                                    </form>
                                    <!-- Social Login Buttons -->
                                    <div class="social-login text-center">
                                        <router-link to="/forgot-password" class="btn"><i class="fa fa-lock m-r-5"></i>
                                            <span>{{
                                                $t('login.forgot_password')
                                                }}</span></router-link>
                                        <!-- <router-link to="/home/signup" class="btn"><i class="fa fa-user m-right-5"></i>
                                            <span>{{
                                                $t('login.create_account') }}</span>
                                        </router-link> -->
                                        <button class="btn" @click="createaccount()"><i
                                                class="fa fa-user m-right-5"></i>
                                            <span>{{
                                                $t('login.create_account') }}</span></button>
                                        <button class="btn" @click="AuthProvider('google')"><i
                                                style="background: linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
                                                class="fa fa-google" aria-hidden="true"></i>
                                            <span>{{ $t('Propertis.login_with_google') }}</span></button>
                                        <button class="btn" @click="AuthProvider('facebook')"><i style="color: #1877F2;"
                                                class="fa fa-facebook" aria-hidden="true"></i>
                                            <span>{{ $t('Propertis.login_with_Facebook') }}</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
            <b-modal id="modal-1" :title="$t('property_detail.Filter_and_sort')">
                <div class="filter-sort">
                    <div class="type-place">
                        <div class="heading">
                            <h2>Type of Place</h2>
                        </div>
                        <div class="place-listing">
                            <ul>
                                <li>
                                    <button type="button" class="place-btn"
                                        @click="selectButton('0'), setActiveButton('Entire Place')"
                                        :class="{ active: activeButton === 'Entire Place' }">Entire Place
                                    </button>
                                </li>
                                <li>
                                    <button type="button" class="place-btn"
                                        :class="{ active: activeButton === 'Shared Room' }"
                                        @click="selectButton('1'), setActiveButton('Shared Room')">Shared Room
                                    </button>
                                </li>
                                <li>
                                    <button type="button" class="place-btn"
                                        :class="{ active: activeButton === 'Private Room' }"
                                        @click="selectButton('2'), setActiveButton('Private Room')">Private Room
                                    </button>
                                </li>
                                <!-- <li>
                                    <button type="button" class="place-btn"
                                        :class="{ active: activeButton === 'With_pool' }"
                                        @click="selectButton('9'), setActiveButton('With_pool')">With Pool
                                    </button>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="sort-sec">
                        <label>{{ $t('Propertis.Sort') }}</label>
                        <template>
                            <b-form-radio-group v-model="selected" name="some-radios" @change="emitSortOrder"
                                class="custom-radio-group">
                                <b-form-radio value="Recommended">{{ $t('property_detail.Recommended') }}</b-form-radio>
                                <b-form-radio value="Discount">Discount</b-form-radio>
                                <b-form-radio value="low-to-high">{{ $t('property_detail.low') }}</b-form-radio>
                                <b-form-radio value="high-to-low">{{ $t('property_detail.high') }}</b-form-radio>
                            </b-form-radio-group>
                        </template>

                    </div>
                    <div class="price-sec">
                        <label>{{ $t('Propertis.roms_and_spaces') }}</label>
                        <div class="row" style="padding: 10px;">
                            <div class="col-lg-8">
                                <p><span>{{ bedrooms }}</span>{{ $t('Propertis.Bedrooms') }}</p>
                            </div>
                            <div class="col-lg-4 d-flex justify-content-end" style="padding-right: 50px;">
                                <div>
                                    <b-input-group>
                                        <b-input-group-prepend>
                                            <button class="btn rom_btn" @click=decrementbed()>-</button>
                                            <p style="color: black;padding: 5px 10px 0px 10px;">{{ bedrooms }}</p>
                                            <button class="btn rom_btn" @click=incrementbed()>+</button>
                                        </b-input-group-prepend>
                                    </b-input-group>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="padding: 10px;">
                            <div class="col-lg-8">
                                <p><span>{{ bathrooms }}</span> {{ $t('Propertis.Bathroom') }}</p>
                            </div>
                            <div class="col-lg-4 d-flex justify-content-end" style="padding-right: 50px;">
                                <div>
                                    <b-input-group>
                                        <b-input-group-prepend>
                                            <button class="btn rom_btn" @click="decrementbath()">-</button>
                                            <p style="color: black;padding: 5px 10px 0px 10px;">{{ bathrooms }}</p>
                                            <button class="btn rom_btn" @click="incrementbath()">+</button>
                                        </b-input-group-prepend>
                                    </b-input-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="price-sec">
                        <label>{{ $t('Propertis.popular_amenities') }}</label>
                        <b-button-group>
                            <b-button :class="['btn', { 'btn-primary': isKitchenActive }]"
                                style="font-size: 16px !important;" @click="toggleKitchen">
                                <span><i class="fa fa-cutlery" aria-hidden="true"></i></span>
                                {{ $t('Propertis.Kitchen') }}
                            </b-button>
                            <b-button :class="['btn', { 'btn-primary': isWifiActive }]"
                                style="font-size: 16px !important;" @click="toggleWifi">
                                <span><i class="fa fa-wifi" aria-hidden="true"></i></span> {{ $t('Propertis.Wifi') }}
                            </b-button>
                            <b-button :class="['btn', { 'btn-primary': isTvActive }]"
                                style="font-size: 16px !important;" @click="toggleTv">
                                <span><i class="fa fa-tv" aria-hidden="true"></i></span>{{ $t('Propertis.TV') }}
                            </b-button>
                            <b-button :class="['btn', { 'btn-primary': isPoolActive }]"
                                style="font-size: 16px !important;" @click="togglePool">
                                <span><i class="fa fa-tv" aria-hidden="true"></i></span>Pool
                            </b-button>
                        </b-button-group>
                    </div>

                    <div class="price-sec">
                        <label>{{ $t('property_detail.Ratings') }}</label>
                        <b-button-group>
                            <b-button
                                :class="{ 'btn-primary': ratingRange === 'desc', 'btn-secondary': ratingRange !== 'desc' }"
                                @click="setratings('desc')">{{ $t('property_detail.Most_to_least') }}</b-button>
                            <b-button
                                :class="{ 'btn-primary': ratingRange === 'asc', 'btn-secondary': ratingRange !== 'asc' }"
                                @click="setratings('asc')"> {{ $t('property_detail.least_to_Most') }}</b-button>
                        </b-button-group>
                    </div>
                    <div class="price-sec border-none">
                        <label> {{ $t('property_detail.Price') }}</label>
                        <b-button-group>
                            <b-button
                                :class="{ 'btn-primary': priceRange === '10-50', 'btn-secondary': priceRange !== '10-50' }"
                                @click="setPriceRange('10-30')">{{ filterPrices[0] }} - {{ filterPrices[1] }}
                                <span>{{ selectedCurrencyIcon }}</span>
                            </b-button>
                            <b-button
                                :class="{ 'btn-primary': priceRange === '50-100', 'btn-secondary': priceRange !== '50-100' }"
                                @click="setPriceRange('30-60')">{{ filterPrices[1] }} - {{ filterPrices[2] }}
                                <span>{{ selectedCurrencyIcon }}</span>
                            </b-button>
                            <b-button
                                :class="{ 'btn-primary': priceRange === '100-200', 'btn-secondary': priceRange !== '100-200' }"
                                @click="setPriceRange('60-90')">{{ filterPrices[2] }} - {{ filterPrices[3] }}
                                <span>{{ selectedCurrencyIcon }}</span>
                            </b-button>
                            <!-- <b-button :class="{ 'btn-primary': priceRange === '', 'btn-secondary': priceRange !== '' }"
                                    @click="setPriceRange('')">{{ $t('property_detail.All') }}</b-button> -->
                        </b-button-group>
                        <!-- <b-button-group style="margin-top: 10px;"><b-button @click="resetPage"> {{
                                $t('property_detail.Reset_All')
                                    }}</b-button></b-button-group> -->
                    </div>
                </div>
            </b-modal>
            <b-modal id="modal-3" ref="myModal" size="xl" :title="$t('Propertis.sel_your_curr')" hide-footer>
                <div class="language-sec">
                    <div class="language-title">
                        <p class="m-0 mb-4  "> {{ $t('Propertis.cur_title') }}
                        </p>
                    </div>
                    <div class="language-title">
                        <h4 class="m-0 mt-4">{{ $t('Propertis.recomend_for_you') }}</h4>
                    </div>
                    <div class="language-list">
                        <ul>
                            <li>
                                <button type="button" class="selection-item" @click="changecurrency('USD')">
                                    <span style="color: #333333">U.S. dollar</span><br>
                                    <span style="color: #6b6b6b">USD</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" class="selection-item" @click="changecurrency('AED')">
                                    <span style="color: #333333">U.A.E. dirham</span><br>
                                    <span style="color: #6b6b6b">AED</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" class="selection-item" @click="changecurrency('SAR')">
                                    <span style="color: #333333"> Saudi Arabian riyal</span><br>
                                    <span style="color: #6b6b6b">SAR</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" class="selection-item" @click="changecurrency('GBP')">
                                    <span style="color: #333333">Pound sterling</span><br>
                                    <span style="color: #6b6b6b">GBP</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" class="selection-item" @click="changecurrency('EUR')">
                                    <span style="color: #333333">Euro</span><br>
                                    <span style="color: #6b6b6b">EUR</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="language-title">
                        <h4 class="m-0 mt-4">{{ $t('Propertis.all_curr') }}
                        </h4>
                    </div>
                    <div class="language-list">
                        <ul>
                            <li v-for="(currency, index) in currencies" :key="index">
                                <div :class="{ 'active-currency': currentcurrency === currency.currency_code }">
                                    <button type="button" class="selection-item"
                                        @click="changecurrency(currency.currency_code)">
                                        <span>{{ currency.name }}</span><br>
                                        <span>{{ currency.currency_code }}</span>
                                        <span v-if="currentcurrency === currency.currency_code"
                                            class="checkmark">✓</span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-modal>
            <b-modal id="modal-2" ref="myModal1" size="xl" :title="$t('Propertis.sel_your_lang')" hide-footer>
                <div class="language-sec">
                    <div class="language-title">
                        <h4 class="m-0">{{ $t('Propertis.recomend_for_you') }}</h4>
                    </div>
                    <div class="language-list">
                        <ul>
                            <li>
                                <button
                                    @click="changeLanguage('en', 'https://staging.cheapcomforts.com/public/images/flag-1.png', 'USD')"
                                    type="button" class="selection-item">
                                    <p><span><img src="../../public/assets/images/flag-1.png"
                                                alt="English"></span><span>English</span></p>
                                </button>
                            </li>
                            <li>
                                <button
                                    @click="changeLanguage('es', 'https://staging.cheapcomforts.com/public/images/flag-2.png', 'EUR')"
                                    type="button" class="selection-item">
                                    <p><span><img src="../../public/assets/images/flag-2.png"
                                                alt="Spanish"></span><span>Español</span></p>
                                </button>
                            </li>
                            <li>
                                <button
                                    @click="changeLanguage('de', 'https://staging.cheapcomforts.com/public/images/flag-3.png', 'EUR')"
                                    type="button" class="selection-item">
                                    <p><span><img src="../../public/assets/images/flag-3.png"
                                                alt="German"></span><span>Deutsch</span></p>
                                </button>
                            </li>
                            <li>
                                <button
                                    @click="changeLanguage('fr', 'https://staging.cheapcomforts.com/public/images/flag-4.png', 'EUR')"
                                    type="button" class="selection-item">
                                    <p><span><img src="../../public/assets/images/flag-4.png"
                                                alt="French"></span><span>Français</span></p>
                                </button>
                            </li>
                            <li>
                                <button
                                    @click="changeLanguage('cn', 'https://staging.cheapcomforts.com/public/images/flag-5.png', 'CNY')"
                                    type="button" class="selection-item">
                                    <p><span><img src="../../public/assets/images/flag-5.png" alt="English"></span><span>
                                            简体中文</span></p>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="language-title">
                        <h4 class="m-0">{{ $t('Propertis.all_lang') }}</h4>
                    </div>
                    <div class="language-list">
                        <ul>
                            <li v-for="(language, index) in this.languages" :key="index">
                                <div :class="{ 'active-currency': selectedLanguage === language.language_code }">
                                    <button
                                        @click="changeLanguage(language.language_code, 'https://staging.cheapcomforts.com/public/images/' + language.country_flag, language.currency)"
                                        type="button" class="selection-item">
                                        <p><span><img
                                                    :src="'https://staging.cheapcomforts.com/public/images/' + language.country_flag"
                                                    alt="English"></span><span>{{ language.language_name }}</span></p>
                                        <span v-if="selectedLanguage === language.language_code"
                                            class="checkmark">✓</span>
                                    </button>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </b-modal>
        </div>

    </div>

</template>
<script>
import Vue from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import 'vue-suggestion/dist/vue-suggestion.css';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import Swal from 'sweetalert2';
import _ from 'lodash';
import axios from 'axios';
import { Appurl } from '../../api';
import API from '@/api'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: 'CustomerHeader',
    selectedPlace: 'any',
    components: {
        DateRangePicker,
        VueRecaptcha
    },
    data() {
        return {
            activeButton: 'Any Type',
            recaptchaToken: '',
            recaptchaToken1: '',
            cameraStream: null,
            isCameraOpen: false,
            profilePhoto: null,
            profilePhotoPreview: null,
            isLoading: true,
            isKitchenActive: false,
            isWifiActive: false,
            isTvActive: false,
            isPoolActive: false,
            selectedButtonIndex: 3,
            query: '',
            suggestions: [],
            filterPrices: [],
            placesService: null,
            isFavorited: false,
            isActive: true,
            buttons: [
                { alt: 'property-image', src: `${Appurl}public/images/entireplace.png`, text: 'property_detail.Entire_Place', type: 'Entire Place' },
                { alt: 'property-image', src: `${Appurl}public/images/10630058.png`, text: 'property_detail.Shared_Room', type: 'Shared Room' },
                { alt: 'property-image', src: `${Appurl}public/images/10630057.png`, text: 'property_detail.Private_Room', type: 'Private Room' },
                { alt: 'property-image', src: `${Appurl}public/images/icon-16.png`, text: 'All', type: '' },
                { alt: 'property-image', src: `${Appurl}public/images/beachfront.png`, text: 'property_detail.Beachfront', type: 'Beachfront' },
                { alt: 'property-image', src: `${Appurl}public/images/mountains.png`, text: 'property_detail.Mountain', type: 'Mountain' },
                { alt: 'property-image', src: `${Appurl}public/images/camping.png`, text: 'property_detail.Camping', type: 'Camping' },
                { alt: 'property-image', src: `${Appurl}public/images/lakefront.png`, text: 'property_detail.Lakefront', type: 'Lakefront' },
                { alt: 'property-image', src: `${Appurl}public/images/ski.png`, text: 'property_detail.snow', type: 'Snow' },
                // { alt: 'property-image', src: `${Appurl}public/images/desert.png`, text: 'property_detail.Desert', type: 'Desert' },
                { alt: 'property-image', src: `${Appurl}public/images/tropical.png`, text: 'property_detail.Tropical', type: 'Tropical' },
                { alt: 'property-image', src: `${Appurl}public/images/withpoll.png`, text: 'property_detail.With_pool', type: 'With_pool' },
                { alt: 'property-image', src: `${Appurl}public/images/central.png`, text: 'Central', type: 'Central' },
                { alt: 'property-image', src: `${Appurl}public/images/outskirt.png`, text: 'Outskirts', type: 'Outskirts' },

            ],
            allProperties: [],
            userLatitude: null,
            userLongitude: null,
            userCity: null,
            nearbyProperties: [],
            showLeftArrow: false,
            showRightArrow: true,
            searchPlaceholder: '',
            favoriteProperties: [],
            discountProperties: [],
            city: '',
            country: '',
            searchQuery: null,
            regionName: '',
            Authorization: '',
            selected: 'Recommended',
            selectedLanguage: '',
            selectedFlagIcon: '',
            // selectedFlagIcon: 'https://staging.cheapcomforts.com/public/images/flag-1.png',
            // selectedCurrencyIcon: 'https://staging.cheapcomforts.com/public/images/currency.png',
            selectedCurrencyIcon: 'USD',
            userProfileImg: 'https://staging.cheapcomforts.com/public/simp.svg',
            showAfterLogin: false,
            showBeforeLogin: true,
            unreadNotification: 0,
            name: '',
            curroutes: null,
            dateRange: {},
            customLocale: {
                daysOfWeek: ['S', 'M', 'T', 'W', 'T', 'F', 'S'], // Custom weekday labels
                monthNames: [
                    'January', 'February', 'March', 'April', 'May', 'June',
                    'July', 'August', 'September', 'October', 'November', 'December'
                ], // Custom month names
            },
            headerclass: 'banner',
            item: {},
            items: [],
            cities: [],
            languages: [],
            currencies: [],
            quantity: '',
            childQuantity: 0,
            petQuantity: 0,
            sdate: '',
            edate: '',
            s1date: '',
            e1date: '',
            currentcurrency: 'USD',
            today: new Date(),
            priceRange: '',
            ratingRange: '',
            hideHeader: false,
            hidehomepage: false,
            showbooking: false,
            loginwithfb: false,
            progileImage: '',
            username: '',
            email: '',
            showSuccess: false,
            success: '',
            stayLogin: false,
            isDisabled: false,
            error: '',
            password: '',
            showError: false,
            repeatPassword: '',
            errorMsg: '',
            show: false,
            successShow: false,
            regBusy: false,
            notfound: false,
            regFname: '',
            regBirthday: '',
            regUsername: '',
            regPassword: '',
            regCpassword: '',
            regEmail: '',
            regEmail1: '',
            regPhone: '',
            currStep: 1,
            currStep1: 1,
            verifycode: '',
            regMsgTextErr: '',
            regMsgTextErr1: '',
            regMsgTextErr2: '',
            regMsgTextSucc: '',
            regBtnText: this.$t('register.next'),
            type: 'password',
            btnText: 'fa fa-eye',
            bedrooms: 1,
            bathrooms: 1,
            serverParams: {
                search: "",
                sort: [
                    {
                        field: 'UserID', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 10 // how many items I'm showing per page
            },
            showManageProperties: false,
            emailnotverified: false,
            emailverified: false,
            phoneverifed: false,
            timeLeft: 600,
            codesendagain: false,
            verifyphonenumber: false,
            codesend: false,
            verifycodenow: true,
            codesent: false,
            vcode: '',
        };

    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.timeLeft / 60);
            const seconds = this.timeLeft % 60;
            return `${this.formatNumber(minutes)}:${this.formatNumber(seconds)}`;
        },
        isMyBookingsRoute() {
            return this.$route.path === '/my_bookings/';
        },
        isAddPropertyRoute() {
            return this.$route.path === '/add_property';
        },
        isIncomeStatisticsRoute() {
            return this.$route.path === '/income_statistics/';
        }
    },
    watch: {
        dateRange: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal === null) {
                    return;
                }
                const startMoment = moment(newVal.startDate);
                const endMoment = moment(newVal.endDate);
                this.s1date = startMoment.format('YYYY-MM-DD');
                this.e1date = endMoment.format('YYYY-MM-DD');
                this.sdate = startMoment.format('MMM DD');
                this.edate = endMoment.format('MMM DD');
                this.$root.$emit('load-items', '', '', '', startMoment.format('YYYY-MM-DD'), endMoment.format('YYYY-MM-DD'), this.quantity);
            }
        },
        '$route': 'updateHeaderVisibility'
    },
    mounted() {
        this.getFilterPrices(),
            this.getUserProperties();
        this.loadButtonStates();
        this.fetchcountry();
        let lang = localStorage.getItem('language');
        let flag = localStorage.getItem('flag');
        let currency = localStorage.getItem('currency');
        if (lang && flag && currency) {
            this.selectedLanguage = lang;
            this.selectedFlagIcon = flag;
            this.changecurrency(currency);
        }
        let ccur = localStorage.getItem('currency');
        if (ccur) {
            this.currentcurrency = ccur;
        } else {

            this.fetchCurrency();
        }
        if (this.$route.path === '/') {
            this.loadPlacesService();
        }
        // this.loadPlacesService();
        // setTimeout(() => {

        // }, 3000);
        API.getallcurriencies(res => {
            this.currencies = res.data.data;
        }, err => {
            console.log(err);
        });
        let fblogin = localStorage.getItem('loginwithfb');
        if (fblogin == 1) {
            this.loginwithfb = true;
        } else {
            this.loginwithfb = false;
        }
        this.updateHeaderVisibility();
        this.getFav();
        this.setPriceRange('');
        let user = JSON.parse(localStorage.getItem("user"));
        this.loginUser = user;
        if (this.loginwithfb) {
            this.progileImage = this.loginUser.avatar
                ? `https://staging.cheapcomforts.com/public/images/${this.loginUser.avatar}`
                : null;
        }
        this.username = this.loginUser
        this.checkScroll();
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                const userLocation = data;
                fetch('http://ip-api.com/json/' + userLocation.ip)
                    .then(response => response.json())
                    .then(data => {
                        localStorage.setItem('region', data.country);
                        this.city = data.city,
                            this.country = data.country;
                        this.regionName = data.regionName;
                        const location = data;
                        API.getCities(this.country, res => {
                            this.cities = res.data.data;
                        }, err => {
                            console.log(err)
                        })
                    }).catch(error => {
                        this.updateSearchPlaceholder('New Hanna', 'USA', 'Maryland');
                        console.error('Error:', error);
                    });
            }).catch(error => {
                console.error('Error:', error);
            });
        const elem = document.getElementById('__BVID__15');
        const button = document.getElementById('__BVID__15___BV_tab_button__');
        const elem1 = document.getElementById('__BVID__17');
        const button1 = document.getElementById('__BVID__17___BV_tab_button__');
        const elem2 = document.getElementById('__BVID__19');
        const button2 = document.getElementById('__BVID__19___BV_tab_button__');
        if (elem && button) {
            document.body.addEventListener('click', this.handleClickOutside);
        }
        if (elem1 && button1) {
            document.body.addEventListener('click', this.handleClickOutside);
        }
        if (elem2 && button2) {
            document.body.addEventListener('click', this.handleClickOutside);
        }
        document.body.addEventListener('click', this.handleClickOutside);
        this.Authorization = localStorage.getItem('isLoggedin');
        setTimeout(() => {
            document.getElementById('__BVID__15').classList.remove("active");
            document.getElementById('__BVID__17').classList.remove("active");
            document.getElementById('__BVID__19').classList.remove("active");
            document.getElementById('__BVID__15___BV_tab_button__').classList.remove("active");
            document.getElementById('__BVID__17___BV_tab_button__').classList.remove("active");
            document.getElementById('__BVID__19___BV_tab_button__').classList.remove("active");
        }, 100);

        if (this.Authorization !== null) {
            this.showAfterLogin = true;
            this.showBeforeLogin = false;
        } else {
            this.showBeforeLogin = true;
        }
        if (this.showAfterLogin == true) {
            API.getFavProperties(
                fevData => {
                    if (fevData.data.booking > 0) {
                        this.showbooking = true;
                    }
                });
        }
        this.setHeaderClass();
    },
    beforeDestroy() {
        document.body.removeEventListener('click', () => { });
    },
    methods: {
        setActiveButton(button) {
            this.activeButton = button;
        },
        setActive(place) {
            this.selectedPlace = place;
        },
        exploreNearby() {
            fetch('https://api.ipify.org?format=json')
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error fetching IP: ${response.status} ${response.statusText}`);
                    }
                    return response.json();
                })
                .then(data => {
                    fetch('http://ip-api.com/json/' + data.ip)
                        .then(response => response.json())
                        .then(locationData => {
                            localStorage.setItem('region', locationData.country);
                            this.city = locationData.city;
                            this.country = locationData.country;
                            this.regionName = locationData.regionName;
                            this.$root.$emit('load-items', this.city);
                            this.updateSearchPlaceholder(this.city, this.country, this.regionName);
                        })
                        .catch(error => {
                            console.error('Error fetching location details:', error);
                        });
                })
                .catch(error => {
                    console.error('Error fetching IP address:', error);
                });

        },
        openCamera() {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    this.cameraStream = stream;
                    this.isCameraOpen = true;
                    setTimeout(() => {
                        if (this.$refs.cameraVideo) {
                            this.$refs.cameraVideo.srcObject = stream;
                        } else {
                            console.error("Video element not found in $refs.");
                        }
                    }, 100);
                })
                .catch(error => {
                    console.error("Camera access denied:", error);
                    this.$swal("Error", "Camera access is required to take a photo.", "error");
                });
        },
        captureImage() {
            const video = this.$refs.cameraVideo;
            if (video) {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, canvas.width, canvas.height);

                // Convert the canvas image to a PNG Data URL and preview it
                this.profilePhotoPreview = canvas.toDataURL('image/png');
                this.profilePhoto = this.dataURItoFile(this.profilePhotoPreview, 'profile_image.png');

                // Close camera stream after capturing
                this.closeCamera();
            }
        },
        closeCamera() {
            if (this.cameraStream) {
                this.cameraStream.getTracks().forEach(track => track.stop());
                this.cameraStream = null;
            }
            this.isCameraOpen = false;
        },
        dataURItoFile(dataURI, filename) {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new File([ab], filename, { type: mimeString });
        },
        onProfileChange(event) {
            // Preview uploaded photo
            this.profilePhoto = event.target.files[0];
            if (this.profilePhoto) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.profilePhotoPreview = e.target.result;
                };
                reader.readAsDataURL(this.profilePhoto);
            }
        },

        removeImage() {
            this.profilePhoto = null;
            this.profilePhotoPreview = null;
        },
        verifyEmail() {
            this.$bvModal.hide('modal-4');
            this.$bvModal.show('modal-6');
        },
        createaccount() {
            this.$bvModal.hide('modal-4');
            this.$bvModal.show('modal-5');
        },
        createaccount1() {
            this.$bvModal.hide('modal-6');
            this.$bvModal.show('modal-5');
        },
        getFilterPrices() {
            let currecny = localStorage.getItem('currency');
            API.getFilterPrices(currecny,
                data => {
                    this.filterPrices = data.data.filterPrices
                }, err => (
                    console.log(err)
                ))
        },
        getUserProperties() {
            if (this.Authorization != null) {
                API.getProperties(this.serverParams,
                    data => {
                        if (data.totalRecords > 0) {
                            this.showManageProperties = true;
                        }
                    }, err => {
                        console.log(err)
                    })
            }
        },
        loadButtonStates() {
            this.isKitchenActive = localStorage.getItem('kitchen') === '1';
            this.isWifiActive = localStorage.getItem('wifi') === '1';
            this.isTvActive = localStorage.getItem('tv') === '1';
            this.isPoolActive = localStorage.getItem('With_pool') === '1';
        },
        toggleKitchen() {
            let ket = localStorage.getItem('kitchen');
            if (ket) {
                localStorage.removeItem('kitchen');
            } else {
                localStorage.setItem('kitchen', 1);
                this.$root.$emit('load-items11', 1, '', '')
            }
            this.isKitchenActive = !this.isKitchenActive;
        },
        toggleWifi() {
            let wifi = localStorage.getItem('wifi');
            if (wifi) {
                localStorage.removeItem('wifi');
            } else {
                localStorage.setItem('wifi', 1);
                this.$root.$emit('load-items11', '', 1, '')
            }
            this.isWifiActive = !this.isWifiActive;
        },
        toggleTv() {
            let tv = localStorage.getItem('tv');
            if (tv) {
                localStorage.removeItem('tv');
            } else {
                localStorage.setItem('tv', 1);
                this.$root.$emit('load-items11', '', '', 1)
            }
            this.isTvActive = !this.isTvActive;
        },
        togglePool() {
            let With_pool = localStorage.getItem('With_pool');
            if (With_pool) {
                localStorage.removeItem('With_pool');
            } else {
                localStorage.setItem('With_pool', 1);
                this.$root.$emit('load-items11', '', '', 1)
            }
            this.isPoolActive = !this.isPoolActive;
        },
        incrementbed() {
            this.bedrooms++;
            localStorage.setItem('bed', this.bedrooms);
            const bath = localStorage.getItem('bath')
            if (bath) {
                this.$root.$emit('load-items8', this.bedrooms, bath)
            } else {
                this.$root.$emit('load-items8', this.bedrooms, '')
            }
        },
        decrementbed() {
            if (this.bedrooms < 1) {
                alert("Negative quantity not allowed");
            } else {
                this.bedrooms--;
                localStorage.setItem('bed', this.bedrooms);
                const bath = localStorage.getItem('bath')
                if (bath) {
                    this.$root.$emit('load-items8', this.bedrooms, bath)
                } else {
                    this.$root.$emit('load-items8', this.bedrooms, '')
                }
            }
        },
        incrementbath() {
            this.bathrooms++;
            localStorage.setItem('bath', this.bathrooms);
            const bed = localStorage.getItem('bed')
            if (bed) {
                this.$root.$emit('load-items8', bed, this.bathrooms)
            } else {
                this.$root.$emit('load-items8', '', this.bathrooms)
            }
        },
        decrementbath() {
            if (this.bathrooms < 1) {
                alert("Negative quantity not allowed");
            } else {
                this.bathrooms--;
                localStorage.setItem('bath', this.bathrooms);
                const bed = localStorage.getItem('bed')
                if (bed) {
                    this.$root.$emit('load-items8', bed, this.bathrooms)
                } else {
                    this.$root.$emit('load-items8', '', this.bathrooms)
                }
            }
        },
        disountProperties() {
            // alert('heres');
            if (this.bathrooms < 1) {
                alert("Error Searching.");
            } else {
                this.bathrooms--;
                localStorage.setItem('bath', this.bathrooms);
                const bed = localStorage.getItem('bed')
                if (bed) {
                    this.$root.$emit('load-items8', bed, this.bathrooms)
                } else {
                    this.$root.$emit('load-items8', '', this.bathrooms)
                }
            }
        },
        changecurrency(cur) {
            this.currentcurrency = cur;
            this.$refs.myModal.hide();
            localStorage.setItem('currency', cur)
            API.getCurrency(cur,
                data => {
                    // console.log("abcd data here",data.data.currency)
                    // this.selectedCurrencyIcon = 'https://staging.cheapcomforts.com/public/images/'+data.data.currency.images;
                    this.selectedCurrencyIcon = data.data.currency.currency_code;
                },
                err => {
                    console.log(err);
                }
            )
            this.getFilterPrices()
            this.$root.$emit('load-items10', cur);
        },
        onVerify(recaptchaToken) {
            this.recaptchaToken = recaptchaToken;
        },
        onVerify1(recaptchaToken) {
            this.recaptchaToken1 = recaptchaToken;
        },
        login() {
            if (this.email == '') {
                this.showError = true;
                this.error = 'Please type Email!';
            } else if (this.password == '') {
                this.showError = true;
                this.error = 'Please type Password!';
            } else if (this.password.length < 6) {
                this.showError = true;
                this.error = 'Password length should be min 6 characters!';
            } else {
                var re = /\S+@\S+\.\S+/;
                if (re.test(this.email)) {
                    this.showError = false;
                    if (!this.recaptchaToken) {
                        this.showError = true;
                        this.error = 'Please complete the reCAPTCHA!';
                        return;
                    }
                } else {
                    this.showError = true;
                    this.error = 'Please type valid Email Address!';
                }
            }
            let email = this.email
            let password = this.password
            let remember_be = this.remember_be
            let recaptchatoke = this.recaptchaToken
            let data = {
                email: email,
                password: password,
                remember_be: remember_be,
                recaptcha_token: recaptchatoke
            }
            this.disabled = true;
            API.loginAsGuest(
                data,
                data => {
                    if (typeof data.token !== "undefined") {
                        localStorage.setItem('isLoggedin', data.token)
                        localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                        localStorage.setItem('user', JSON.stringify(data.user))
                        localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                        localStorage.setItem('password', this.password)
                        if (this.remember_be == 'true') {
                            localStorage.setItem('email', this.email)
                            localStorage.setItem('password', this.password)
                        }
                        this.$refs.loginModalRef.hide()
                        const originalUrl = localStorage.getItem('originalUrl');
                        if (originalUrl) {
                            location.reload();
                            this.$router.push(originalUrl);
                            localStorage.removeItem('originalUrl');
                        } else {
                            location.reload();
                            this.$router.push({ name: 'home' });
                        }
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                        this.emailnotverified = true;
                    }
                },
                err => {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: err.data.message,
                    })
                }

            )
        },
        AuthProvider(provider) {
            var self = this;

            this.$auth
                .authenticate(provider)
                .then((response) => {
                    // self.SocialLogin(provider, response);
                })
                .catch((err) => {
                    if (err.message === "Auth popup window closed") {
                        this.showError = true;
                        this.error = "Authentication was cancelled. Please try again.";
                    } else {
                        console.error(err);
                    }
                });

            const handleAuthMessage = (event) => {
                if (event.data.type === 'authSuccess') {
                    const userEmail = event.data.email;
                    window.removeEventListener('message', handleAuthMessage); // Remove listener after handling message
                    // this.$router.push({ name: 'home' });
                    let data = {
                        email: event.data.email,
                        password: 'password',
                    }
                    API.loginAsGuest(
                        data,
                        data => {
                            if (typeof data.token !== "undefined") {
                                localStorage.setItem('isLoggedin', data.token)
                                localStorage.setItem('loginwithfb', '1')
                                localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                                localStorage.setItem('user', JSON.stringify(data.user))
                                localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                                localStorage.setItem('password', this.password)
                                if (this.remember_be == 'true') {
                                    localStorage.setItem('email', this.email)
                                    localStorage.setItem('password', this.password)
                                }
                                this.$refs.loginModalRef.hide()
                                location.reload();
                                // this.$router.push({ name: 'home' });
                                const originalUrl = localStorage.getItem('originalUrl');
                                if (originalUrl) {
                                    location.reload();
                                    // If original URL exists, redirect back to it
                                    this.$router.push(originalUrl);
                                    localStorage.removeItem('originalUrl'); // Remove the original URL from storage
                                } else {
                                    location.reload();
                                    // If no original URL, redirect to a default route
                                    this.$router.push({ name: 'home' });
                                }
                            } else {
                                this.$swal({
                                    type: 'error',
                                    title: 'Oops...',
                                    text: data.message,
                                })
                            }
                        },
                        err => {
                            this.$swal({
                                type: 'error',
                                title: 'Oops...',
                                text: err.message,
                            })
                        }

                    )

                } else if (event.data === 'authError') {
                    window.removeEventListener('message', handleAuthMessage); // Remove listener after handling message
                    this.showError = true;
                    this.error = "Social login failed. Please try again.";
                }
            };

            window.addEventListener('message', handleAuthMessage);
        },
        fetchCurrency() {
            axios.get('https://ipapi.co/currency/')
                .then(response => {
                    this.currentcurrency = response.data;
                    localStorage.setItem('currency', response.data);
                })
                .catch(error => {
                    this.currency = 'USD';
                    localStorage.setItem('currency', 'USD');
                    console.error('Error fetching currency:', error);
                });
        },
        fetchcountry() {
            axios.get('https://ipapi.co/country_name/')
                .then(response => {
                    API.getalllanguages(
                        response.data,
                        (response) => {
                            this.languages = response.data.data;
                            let lang = localStorage.getItem('language')
                            let flag = localStorage.getItem('flag')
                            let currecny = localStorage.getItem('currency')
                            if (lang) {
                                this.selectedLanguage = lang;
                                this.changeLanguage(lang, flag, currecny);
                            } else {
                                this.changeLanguage(response.data.languagecode, 'https://staging.cheapcomforts.com/public/images/' + response.data.flag, currecny);
                                this.selectedLanguage = response.data.languagecode
                            }

                        },
                        (error) => {
                            console.log('Error:', error);
                        }
                    );
                })
                .catch(error => {
                    this.currency = 'USD';
                    console.error('Error fetching currency:', error);
                });
        },
        async loadPlacesService() {
            const loader = new Loader({
                apiKey: process.env.VUE_APP_GOOLE_PLACES_API_KEY,
                version: 'weekly',
                libraries: ['places']
            });

            try {
                await loader.load();
                this.placesService = new window.google.maps.places.AutocompleteService();
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        },
        onInput() {
            if (this.query.length > 0) {
                document.getElementById('__BVID__15').classList.remove("active");
            }
            if (this.query.length < 3) {
                this.suggestions = [];
                return;
            }
            this.placesService.getPlacePredictions(
                { input: this.query, types: ['geocode'] },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        this.suggestions = predictions;
                    } else {
                        console.error('Error fetching place predictions:', status);
                        this.suggestions = [];
                    }
                }
            );
        },
        selectSuggestion(suggestion) {
            this.query = suggestion.description;
            this.searchQuery = suggestion.description;
            this.suggestions = [];
        },
        handleKeyUp(event) {
            if (event.key === 'Enter') {
                this.callApiOnEnter();
            }
        },
        callApiOnEnter() {

            // document.getElementById('__BVID__15').classList.toggle("active");
            // document.getElementById('__BVID__15___BV_tab_button__').classList.toggle("active");
            const inputValue = this.searchQuery.toLowerCase().trim();
            const words = inputValue.split(/\s*,\s*/);
            console.log(words, words.length);
            let country = '';
            let city = '';
            let state = '';
            switch (words.length) {
                case 1:
                    country = words[0];
                    break;
                case 2:
                    city = words[0];
                    country = words[1];
                    break;
                case 3:
                    city = words[0];
                    state = words[1];
                    country = words[2];
                    break;
            }

            this.$root.$emit('load-items7', city, state, country);
        },
        bookings() {
            let Authorization = localStorage.getItem('isLoggedin');
            if (Authorization) {
                this.$router.push({ name: 'my_bookings' });
            } else {
                this.$router.push({ name: 'guest_login' });
            }

        },
        incomeStatistics() {
            let Authorization = localStorage.getItem('isLoggedin');
            if (Authorization) {
                this.$router.push({ name: 'income_statistics' });
            } else {
                this.$router.push({ name: 'guest_login' });
            }

        },
        manageProperties() {
            let Authorization = localStorage.getItem('isLoggedin');
            if (Authorization) {
                this.$router.push({ name: 'manage_properties' });
            } else {
                this.$router.push({ name: 'guest_login' });
            }

        },
        // goToDashboard() {
        //     let Authorization = localStorage.getItem('isLoggedin');
        //     // console.log('Auth:', Authorization);
        //     if (Authorization) {
        //         this.$router.push({ name: 'dashboard' });
        //     } else {
        //         this.$router.push({ name: 'guest_login' });
        //     }

        // },
        goToDashboard() {
            let Authorization = localStorage.getItem('isLoggedin');
            if (Authorization) {
                const user = JSON.parse(localStorage.getItem('user')); // Get user data
                console.log("USER DATA HERE:" , user);
                if (user && user.permissions && user.permissions.length > 0) {
                    const permission = user.permissions[0].name;
                    if (permission === 'property_owner') {
                        // Redirect to the Property Owner dashboard
                        this.$router.push({ name: 'dashboard' }); // Assuming 'owner_dashboard' is your route name
                    } else if (permission === 'super_admin') {
                        // Redirect to the Super Admin dashboard
                        this.$router.push({ path: '/Admin/' });
                    } else {
                        // Handle other permissions or no permissions (e.g., 'guest')
                        // You can show a message, redirect to a different route, etc.
                        console.error("User does not have permission to access the dashboard."); // Redirect or display message.
                    }
                }
            } else {
                // Redirect to login if not logged in
                this.$router.push({ name: 'guest_login' });
            }
        },
        toggleFavorite() {
            let Authorization = localStorage.getItem('isLoggedin');
            if (Authorization) {
                this.isFavorited = !this.isFavorited;
                if (this.isFavorited) {
                    this.$root.$emit('load-items3', this.loginUser.id)
                } else {
                    this.$root.$emit('load-items3', '')
                }
            } else {
                this.$bvModal.show('modal-4');
                // this.$router.push({ name: 'guest_login' });
            }


        },

        handleTabClick(event) {
            if (event && event.keyCode === 32) {
                this.query = this.query + ' ';
                return; // Exit the function to avoid focusing
            }
            if (this.query.length > 0) {
                this.toggle_BV_tab_button();
                this.focusButton();
                return; // Exit the function to avoid focusing
            }
            this.removeClass();
            this.focusButton();
            this.focusButtonVisibleIndication();
        },
        focusButton() {
            let myButton = document.getElementById('type-search');
            if (myButton) {
                myButton.focus();
            }
        },

        focusButtonVisibleIndication() {
            let myButton = document.getElementById('type-search');
            if (myButton) {
                myButton.focus({ focusVisible: true });
            }
        },
        clearSearch() {
            this.searchQuery = '';
            location.reload();
        },
        updateHeaderVisibility() {
            this.hideHeader = this.$route.path.startsWith('/Mission') || this.$route.path.startsWith('/property/detail/') || this.$route.path.startsWith('/Faq') || this.$route.path.startsWith('/property/rating') || this.$route.path.startsWith('/About') || this.$route.path.startsWith('/Contact') || this.$route.path.startsWith('/my_bookings') || this.$route.path.startsWith('/add_property') || this.$route.path.startsWith('/property_details') || this.$route.path.startsWith('/income_statistics') || this.$route.path.startsWith('/property/payment') || this.$route.path.startsWith('/privacypolicy');
            if (this.$route.path == '/') {
                this.hidehomepage = true;
            } else {
                this.hidehomepage = false
            }
        },
        resetPage() {
            localStorage.removeItem('bed');
            localStorage.removeItem('bath');
            window.location.reload();
        },
        setPriceRange(range) {
            this.priceRange = range;
            this.$root.$emit('load-items2', range.split('-')[0], range.split('-')[1]);
        },
        setratings(range) {
            this.ratingRange = range;
            this.$root.$emit('load-items4', range);
        },
        Addproperty() {
            let Authorization = localStorage.getItem('isLoggedin');
            if (Authorization) {
                const user = JSON.parse(localStorage.getItem('user'));
                const permission = user.permissions[0].name;
                if (permission == 'property_owner') {
                    this.$router.push({ path: '/add_property' });
                } else if (permission == 'super_admin') {
                    this.$router.push({ path: '/Admin/' });
                } else if (permission == 'guest') {
                    this.$swal({
                        title: 'If you want to list a property, you need to upgrade your account to Property Owner.',
                        type: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Update Now'
                    }).then((result) => {
                        let data = {
                            email: this.loginUser.email,
                            password: localStorage.getItem('password'),
                        }
                        if (result.value) {
                            API.Sendmail(this.loginUser.id, res => {
                                if (res.status == 200) {
                                    alert(res.data.message);
                                    this.$session.clear();
                                    localStorage.removeItem('userPermissions');
                                    localStorage.removeItem('isLoggedin');
                                    localStorage.removeItem('email');
                                    localStorage.removeItem('password');
                                    this.showAfterLogin = false;
                                    this.showBeforeLogin = true;
                                    API.loginAsGuest(
                                        data,
                                        data => {
                                            if (typeof data.token !== "undefined") {
                                                localStorage.setItem('isLoggedin', data.token)
                                                localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                                                localStorage.setItem('user', JSON.stringify(data.user))
                                                localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                                                localStorage.setItem('password', this.password)
                                                if (this.remember_be == 'true') {
                                                    localStorage.setItem('email', this.email)
                                                    localStorage.setItem('password', this.password)
                                                }
                                                const originalUrl = localStorage.getItem('originalUrl');
                                                if (originalUrl) {
                                                    window.location.reload();

                                                } else {
                                                    window.location.reload();

                                                }
                                            } else {
                                                this.$swal({
                                                    type: 'error',
                                                    title: 'Oops...',
                                                    text: data.message,
                                                })
                                            }
                                        },

                                        err => {
                                            this.$swal({
                                                type: 'error',
                                                title: 'Oops...',
                                                text: err.message,
                                            })
                                        }

                                    )
                                    this.$router.push({ path: '/add_property' });
                                    console.log(res.data.message);
                                }
                            }, err => {
                                console.log(err)
                            })
                        }
                    });
                }
            } else {
                this.$bvModal.show('modal-4');
                // this.$router.push({ name: 'guest_login' });
            }

        },
        getFav() {
        },
        toggleDropdown() {
            this.$refs.dropdown.show();
        },
        filterByPriceRange(minPrice, maxPrice) {
            this.$emit('filter-by-price', { minPrice, maxPrice });
        },
        resetFilter() {
            this.$emit('reset-filter');
        },
        emitSortOrder() {
            if (this.selected === 'Discount') {
                this.$root.$emit('load-items12');
            } else {
                this.$root.$emit('load-items5', this.selected);
            }
        },
        filterDiscountedProperties() {
            // Assuming `properties` contains a `discount` property to filter on
            const discountedProperties = this.discountProperties.filter(property => property.discount && property.discount > 0);
            // console.log("discountedProperties", discountedProperties);
            // this.$root.$emit('load-discounted-items', discountedProperties);
        },
        updateSearchPlaceholder(city, country, regionName) {
            this.city = city;
            this.country = country;
            this.regionName = regionName;
            const lastSearchedLocation = `${city}, ${country}`;
            localStorage.setItem('lastSearchedLocation', lastSearchedLocation);
            this.searchPlaceholder = lastSearchedLocation;
        },
        componentDidMount() {
            const lastSearchedLocation = localStorage.getItem('lastSearchedLocation');
            if (lastSearchedLocation) {
                this.searchPlaceholder = lastSearchedLocation;
            }
        },
        handleClickOutside(event) {
            document.getElementById('main_bar').classList.remove("active");
            const elem = document.getElementById('__BVID__15');
            const elem1 = document.getElementById('__BVID__92');
            const elem2 = document.getElementById('__BVID__17');
            const elem3 = document.getElementById('__BVID__19');
            const button = document.getElementById('__BVID__15___BV_tab_button__');
            const button1 = document.getElementById('__BVID__92___BV_tab_button__');
            const button2 = document.getElementById('__BVID__17___BV_tab_button__');
            const button3 = document.getElementById('__BVID__19___BV_tab_button__');
            if (elem && button && elem.classList && button.classList) {
                if (event.target !== elem && !elem.contains(event.target) && event.target !== button && !button.contains(event.target)) {
                    elem.classList.remove('active');
                    button.classList.remove('active');
                }
            }
            if (elem1 && button1 && elem1.classList && button1.classList) {
                if (event.target !== elem1 && !elem1.contains(event.target) && event.target !== button1 && !button1.contains(event.target)) {
                    elem1.classList.remove('active');
                    button1.classList.remove('active');
                }
            }
            if (elem2 && button2 && elem2.classList && button2.classList) {
                if (event.target !== elem2 && !elem2.contains(event.target) && event.target !== button2 && !button2.contains(event.target)) {
                    elem2.classList.remove('active');
                    button2.classList.remove('active');
                }
            }
            if (elem3 && button3 && elem3.classList && button3.classList) {
                if (event.target !== elem3 && !elem3.contains(event.target) && event.target !== button3 && !button3.contains(event.target)) {
                    elem3.classList.remove('active');
                    button3.classList.remove('active');
                }
            }
        },
        removeClass() {
            const clickEvent = new Event('click', { bubbles: true, cancelable: true });
            document.body.dispatchEvent(clickEvent);
            document.getElementById('main_bar').classList.add("active");
            document.getElementById('__BVID__15').classList.toggle("active");
            document.getElementById('__BVID__15___BV_tab_button__').classList.toggle("active");
        },
        toggle_BV_tab_button() {
            const clickEvent = new Event('click', { bubbles: true, cancelable: true });
            document.body.dispatchEvent(clickEvent);
            // document.getElementById('__BVID__15').classList.toggle("active");
            document.getElementById('__BVID__15___BV_tab_button__').classList.toggle("active");
        },
        removeClass1() {
            document.getElementById('main_bar').classList.add("active");
            document.getElementById('__BVID__17').classList.toggle("active");
            document.getElementById('__BVID__17___BV_tab_button__').classList.toggle("active");
        },
        removeClass2() {

            const clickEvent = new Event('click', { bubbles: true, cancelable: true });
            document.body.dispatchEvent(clickEvent);
            document.getElementById('main_bar').classList.add("active");
            document.getElementById('__BVID__19').classList.toggle("active");
            document.getElementById('__BVID__19___BV_tab_button__').classList.toggle("active");
        },
        setHeaderClass() {
            this.curroutes = this.$route.path.split('/');
            if (this.curroutes[1] === 'customer' && this.curroutes[2] === 'inventory') {
                this.headerclass = 'banner';
            } else {
                this.headerclass = 'nobanner';
            }
        },
        changeLanguage(language, flagIcon, currency) {
            Vue.prototype.$EventBus.$emit('changeLanguage', language);
            this.selectedLanguage = language;
            this.selectedFlagIcon = flagIcon;
            localStorage.setItem('language', language);
            localStorage.setItem('flag', flagIcon);
            this.isLoading = false;
            // console.log('isLoading', this.isLoading);
            this.$emit('isLoading', this.isLoading);
            this.changecurrency(currency);
            this.$refs.myModal1.hide();
        },
        redirectLogin(path, type) {
            if (type === 'customer') {
                const customer_token = localStorage.getItem('access_token');
                const customer = JSON.parse(localStorage.getItem('user'));
                if ((customer_token !== null && customer_token !== "") && (customer !== null && customer !== "")) {
                    if (customer.user_role === 'customer') {
                        this.$router.push({ path: '/customer' });
                    }
                } else {
                    this.$router.push({ path: path });
                }
            }
        },
        logout() {
            const user = JSON.parse(localStorage.getItem('user'));
            this.$session.clear();
            localStorage.removeItem('userPermissions');
            localStorage.removeItem('isLoggedin');
            localStorage.removeItem('email');
            localStorage.removeItem('password');
            localStorage.removeItem('super_admin_email');
            this.showAfterLogin = false;
            this.showBeforeLogin = true;
            this.$router.push({ name: 'home' });
        },
        increment() {
            this.quantity++;
            this.$root.$emit('load-items', '', '', '', this.s1date, this.e1date, this.quantity, this.childQuantity, this.petQuantity);
        },
        decrement() {
            if (this.quantity > 1) {
                this.quantity--;
                this.$root.$emit('load-items', '', '', '', this.s1date, this.e1date, this.quantity, this.childQuantity, this.petQuantity);
            }
        },
        childIncrement() {
            this.childQuantity++;
            this.$root.$emit('load-items', '', '', '', this.s1date, this.e1date, this.quantity, this.childQuantity, this.petQuantity);
        },
        childDecrement() {
            if (this.childQuantity > 0) {
                this.childQuantity--;
                this.$root.$emit('load-items', '', '', '', this.s1date, this.e1date, this.quantity, this.childQuantity, this.petQuantity);
            }
        },
        petIncrement() {
            this.petQuantity++;
            this.$root.$emit('load-items', '', '', '', this.s1date, this.e1date, this.quantity, this.childQuantity, this.petQuantity);

        },
        petDecrement() {
            if (this.petQuantity > 0) {
                this.petQuantity--;
                this.$root.$emit('load-items', '', '', '', this.s1date, this.e1date, this.quantity, this.childQuantity, this.petQuantity);
            }
        },
        redirectToPayment() {
            if (!this.dateRange.startDate || !this.dateRange.endDate) {
                alert('Please Select Available Dates.');
            } else {
                let startDate = moment(this.dateRange.startDate);
                let formattedstartDate = startDate.format('YYYY-MM-DD');
                let endDate = moment(this.dateRange.endDate);
                let formattedendDate = endDate.format('YYYY-MM-DD');
                this.$router.push({ name: 'property_payment', params: { totalPrice: this.totalPrice, startDate: formattedstartDate, endDate: formattedendDate } });
            }
        },
        scrollLeft() {
            this.$refs.buttonSwitcher.scrollBy({
                top: 0,
                left: -200,
                behavior: 'smooth'
            });
            this.checkScroll();
        },
        scrollRight() {
            this.$refs.buttonSwitcher.scrollBy({
                top: 0,
                left: 200,
                behavior: 'smooth'
            });
            this.checkScroll();
        },
        selectButton(index) {
            console.log(index);
            this.selectedButtonIndex = index;
            this.$root.$emit('load-items1', this.buttons[index].type);
        },
        checkScroll() {
            const buttonSwitcher = this.$refs.buttonSwitcher;
            const { scrollLeft, scrollWidth, clientWidth } = buttonSwitcher;
            this.showLeftArrow = scrollLeft > 0;
            this.showRightArrow = scrollLeft + clientWidth < scrollWidth;
        },
        redirect(path) {
            //window.location.href = '/';
            this.$router.push({ path: path });
            // Bus.$emit('route-changes');

        },
        enforcePhoneFormat() {
            let x = this.regPhone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

            this.regPhone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        verifynow() {
            let data = {
                code: this.verifycode,
                email: this.regEmail1,
            }
            API.verify2fa(
                data,
                data => {
                    if (data.error) {
                        this.regMsgTextErr2 = data.error;
                        this.notfound = true;
                    } else if (data.message) {
                        this.currStep1 = 2;
                        this.emailnotverified = false;
                        this.emailverified = true;
                        this.$bvModal.hide('modal-6');
                        this.$bvModal.show('modal-4');
                    }
                },
                err => {
                    this.regMsgTextErr2 = err.data.error;
                    this.notfound = true;
                    console.log(err)
                });
        },
        sendagain() {
            let data = {
                email: this.regEmail1,
            }
            API.send2faCode(
                data,
                data => {
                    if (data.error) {
                        this.regMsgTextErr1 = data.error;
                        this.notfound = true;
                    } else if (data.message) {
                        this.currStep1 = 2;
                        this.timeLeft = 600;
                        this.codesendagain = true;
                    }
                },
                err => {
                    console.log(err)
                });
        },
        verifybuton() {
            if (this.currStep1 == 1) {
                if (this.regEmail1 == '') {
                    this.regMsgTextErr1 = this.$t('validations.email');
                } else {
                    var re1 = /\S+@\S+\.\S+/;
                    if (re1.test(this.regEmail1)) {
                        let data = {
                            email: this.regEmail1,
                        }
                        API.send2faCode(
                            data,
                            data => {
                                if (data.error) {
                                    this.regMsgTextErr1 = data.error;
                                    this.notfound = true;
                                } else if (data.message) {
                                    this.currStep1 = 2;
                                    const interval = setInterval(() => {
                                        if (this.timeLeft > 0) {
                                            this.timeLeft -= 1;
                                        } else {
                                            clearInterval(interval);
                                        }
                                    }, 1000);
                                }
                            },
                            err => {
                                console.log(err)
                            });

                    } else {
                        this.regMsgTextErr1 = this.$t('validations.v_email');
                    }
                }
            }
        },
        formatNumber(num) {
            return num < 10 ? `0${num}` : num;
        },
        sendverifycode() {
            let data = {
                phone: this.regPhone,
            }
            API.sendverificationCode(
                data,
                data => {
                    if (data.error) {
                        this.regMsgTextErr1 = data.error;
                        this.notfound = true;
                    } else if (data.status == 'ok') {
                        this.codesend = true;
                        this.verifycodenow = false;
                        this.codesent = true;
                    }
                },
                err => {
                    console.log(err)
                });
        },
        vrfynow() {
            if (this.vcode == '') {
                this.regMsgTextErr = 'Please Enter the Code';
            } else {
                let data = {
                    code: this.vcode,
                }
                API.sendverificationCode(
                    data,
                    data => {
                        if (data.error) {
                            this.regMsgTextErr1 = data.error;
                            this.notfound = true;
                        } else if (data.status == 'ok') {
                            this.verifyphonenumber = true;
                            this.phoneverifed = true;
                        }
                    },
                    err => {
                        console.log(err)
                    });
            }
        },
        regSub() {
            this.regMsgTextErr = '';
            if (this.currStep == 1) {
                if (this.regFname == '') {
                    this.regMsgTextErr = this.$t('validations.name');
                } else if (this.regEmail == '') {
                    this.regMsgTextErr = this.$t('validations.email');
                } else if (this.regPhone == '' || this.regPhone.length != 14) {
                    this.regMsgTextErr = this.$t('validations.phone');
                } else {
                    var re1 = /\S+@\S+\.\S+/;
                    if (re1.test(this.regEmail)) {

                        this.currStep = 2;
                        this.regBtnText = this.$t('register.next');

                    } else {
                        this.regMsgTextErr = this.$t('validations.v_email');
                    }
                }
            } else if (this.currStep == 2) {
                this.regBtnText = this.$t('register.sign_up');
                if (this.regPassword == '') {
                    this.regMsgTextErr = this.$t('validations.password');
                } else if (this.regPassword.length < 6) {
                    this.regMsgTextErr = this.$t('validations.min_password');
                } else if (this.regCpassword == '') {
                    this.regMsgTextErr = this.$t('validations.password');
                } else if (this.regPassword != this.regCpassword) {
                    this.regMsgTextErr = this.$t('validations.match_pass');
                } else if (!this.regMsgTextErr) {
                    this.currStep = 3;
                    this.regBtnText = this.$t('register.next');
                    return;
                }
            } else if (this.currStep == 3) {
                if (!this.profilePhoto) {
                    this.regMsgTextErr = this.$t('Profile photo is required.');
                } else {
                    this.currStep = 4;
                    // this.regBtnText = this.$t('register.signing_up');
                    // // this.currStep = 4;
                    // this.register();
                }
            } else if (this.currStep == 4) {
                if (!this.recaptchaToken1) {
                    this.showError = true;
                    this.regMsgTextErr = 'Please complete the reCAPTCHA!';
                    return;
                } else {
                    this.regBtnText = this.$t('register.signing_up');
                    // // this.currStep = 4;
                    this.register();
                }
                this.register();
            }
        },
        ageValidation(birthYear) {
            const dateBirth = new Date(birthYear);
            const today = new Date();
            const diff = new Date(today.getTime() - dateBirth.getTime());
            const age = diff.getUTCFullYear() - 1970;
            return age;
        },
        bckBtn() {
            if (this.currStep > 1) {
                this.regMsgTextErr = '';
                this.regBtnText = this.$t('register.next');
                this.currStep = this.currStep - 1;
            }
        },
        showPassword() {
            if (this.type === 'password') {
                this.type = 'text'
                this.btnText = 'fa fa-eye-slash'
            } else {
                this.type = 'password'
                this.btnText = 'fa fa-eye'
            }
        },
        register_first() {
            this.$v.$touch();
        },

        register() {
            this.regBusy = true;
            this.regBtnText = this.$t('register.signing_up');
            let formData = new FormData();
            formData.append('name', this.regFname);
            formData.append('email', this.regEmail);
            formData.append('password', this.regPassword);
            formData.append('phone', this.regPhone);
            formData.append('user', 'guest');
            formData.append('recaptcha_token', this.recaptchaToken1);
            if (this.profilePhoto) {
                formData.append('avatar', this.profilePhoto); // Append the image file
            }
            API.registerUser(
                formData,
                data => {
                    if (data.token) {
                        this.$refs.myModalRef.hide()
                        this.$router.push("/home/login");
                        location.reload();
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                    this.isDisabled = false;
                },
                err => {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                }
            )
        },
        onFileChange(event) {  // To preview image
            this.profilePhoto = event.target.files[0];
            if (this.profilePhoto) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.profilePhotoPreview = e.target.result;
                };
                reader.readAsDataURL(this.profilePhoto);
            }
        },
        regSingIn() {
            location.reload();
            window.location.href = "/login";
        },
        googleLogin() {
            window.location.href = "/redirect-google";
        },
        facebookLogin() {
            window.location.href = "/redirectfb";
        },
        instagramLogin() {
            window.location.href = "/redirectinsta";
        },

    }
};
</script>


<style>
.nodisply {
    display: none !important;
}

#__BVID__17 {
    display: none !important;
}

.icon-list-info-star {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.icon-list-info-star span i {
    padding: 0 !important;
    background-color: transparent !important;
}

.icon-list-info-star span i:hover {
    background-color: transparent !important;
}

.icon-list-info-star:hover {
    background: #70d3ff !important;
}

.icon-list-info-star:hover span i {
    color: #fff !important;
}

.button-switcher-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.button-switcher {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    width: 100%;
    white-space: nowrap;
    gap: 32px;
    justify-content: space-between;
}

.button-switcher::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Webkit browsers */
}

.button-switcher {
    -ms-overflow-style: none;
    /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
}

.arrow {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.related-filter {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.left-arrow {
    margin-right: 10px;
}

.right-arrow {
    margin-left: 10px;
}

/* Ensure buttons are in a single line */
html header.new-header {
    padding: 0;
}

.date-picker .vue-daterange-picker {
    /* max-width: 220px; */
    width: 100%;
    margin-bottom: 15px;
}

html .new-header .container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1832px;
}

html .new-header .container nav.navbar {
    padding: 22px 0;
}

/* html ul.navbar-nav li.nav-item a.nav-link {
    padding: 8px 16px 8px 16px;
} */
html ul.navbar-nav li.b-nav-dropdown a.dropdown-toggle {
    padding: 0px;
}

html ul.navbar-nav li.b-nav-dropdown a.dropdown-toggle::after {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    font: normal normal normal 24px / 1 "themify";
    content: "\e64b";
    width: auto;
    height: auto;
    vertical-align: middle;
    line-height: 0.625rem;
    font-size: 0.875rem;
    margin-left: 0.3rem;
    display: block !important;
    position: absolute;
    right: 14px;
    top: 11px;
    color: #000;
    font-weight: bolder;
}

html .new-header .container nav.navbar li.form-inline {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

html .new-header .container nav.navbar .form-inline form.form-inline {
    display: flex;
    flex-wrap: nowrap;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    width: 500px;
    position: relative;
}

html .new-header .container nav.navbar .form-inline form.form-inline input {
    border: 1px solid gray;
    width: 100%;
    padding: 10px 30px 10px 10px;
    color: rgba(0, 0, 0, 0.9);
}

html .new-header .container nav.navbar .form-inline form.form-inline button.btn-sm {
    background-color: transparent;
    position: absolute;
    right: 20px;
    padding: 0;
    border: none;
}

html .new-header .container nav.navbar .form-inline form.form-inline button.btn-sm i {
    color: rgba(0, 0, 0, 0.9);
}

.header-top.before_login_search nav.navbar {
    box-shadow: none;
    padding: 10px 0;
}

.header-top.before_login_search nav.navbar .vs__selected.vs__input-group input {
    width: 100%;
    height: 38px;
    margin: 0;
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 0 15px;
}

.header-top .vue-suggestiona {
    border-radius: 15px;
}

.header-top.before_login_search nav.navbar .vs__selected.vs__input-group {
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 15px;
    border: 0;
}

.header-top.before_login_search nav.navbar .suggestion-cross i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #42aaff !important;
}

.header-top.before_login_search nav.navbar #nav-collapse {
    justify-content: flex-start;
}

.header-top.before_login_search nav.navbar #nav-collapse1 {
    justify-content: flex-end;
}

.navbar-brand {
    flex: none !important;
}

.navbar-nav {
    align-items: center;
}

.dropdown-menu.logo-menu.profile-menu {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 0px;
    border-radius: 5px;
}

.filter-sort fieldset legend {
    font-weight: 600;
}

.filter-sort .custom-control.custom-radio {
    margin-bottom: 14px;
    padding-left: 40px;
}

.custom-control-inline {
    display: block;
}

.filter-sort .custom-control.custom-radio label {
    padding-top: 7px;
    font-size: 14px;
    font-weight: 400;
}

.filter-sort .sort-sec {
    border-bottom: 1px solid #e7e7e8;
    padding-bottom: 15px;
    margin-bottom: 5px;
}

.filter-sort .price-sec label,
.filter-sort .sort-sec label {
    font-weight: 600;
    display: block;
    margin-bottom: 16px;
    font-size: 15px;
    color: #333;
}

.filter-sort .price-sec {
    padding: 15px 0 20px;
    border-bottom: 1px solid #e7e7e8;
    margin-bottom: 5px;
}

.filter-sort .price-sec .btn-group {
    display: flex;
    gap: 10px;
}

.filter-sort .price-sec .btn-group button.btn {
    border: 1px solid #c0c2c4;
    box-shadow: none;
    width: auto;
    padding: 10px 16px;
    display: inline-block;
    max-width: max-content;
    border-radius: 5px;
    background: transparent;
    color: #000;
    padding: 11px !important;
}

.filter-sort .price-sec.border-none {
    border: 0;
}

.filter-sort .price-sec .btn-group button.btn:hover {
    background: #c8daf5;
    border-color: #c8daf5;
}

.sort-sec .custom-control-label::before {
    position: absolute;
    top: 4px;
    left: -40px;
    display: block;
    width: 24px;
    height: 24px;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd;
}

.sort-sec .custom-control-label::after {
    position: absolute;
    top: 4px;
    left: -40px;
    display: block;
    width: 24px;
    height: 24px;
    content: "";
    background: 50% / 50% 50% no-repeat;
}

#modal-xl {
    padding: 0 !important;
}

#modal-xl .modal-xl {
    max-width: 100%;
    margin: 0;
}

#modal-xl .modal-content {
    border-radius: 0;
    height: 100vh;
}

#modal-xl .modal-content .modal-body {
    padding: 0;
}

#modal-xl .modal-content .modal-footer {
    display: none;
}

#modal-xl .map-sec {
    height: calc(100vh - 58px);
    background-color: #a4ddf5;
}

#modal-xl .map-sec iframe {
    width: 100%;
    height: 100%;
    vertical-align: top;
}

.language-sec .language-title {
    margin-bottom: 10px;
}

.language-sec .language-title h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
}

.language-list ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.language-list ul li {
    width: 20%;
}

.language-list ul li .selection-item {
    border: 0;
    padding: 12px;
    width: 100%;
    text-align: left;
    background: #fff;
    border-radius: 4px;
}

.language-list ul li .selection-item:hover {
    background: #dddddd70;
}

.language-list ul li .selection-item p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    line-height: 1.2;
}

.language-list ul li .selection-item p img {
    width: 22px;
    height: 22px;
    object-fit: cover;
    border-radius: 100px;
}

span.langiage-btn img {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    box-shadow: 0 0 5px 0px #00000063;
}

.top-head .navbar-light .navbar-nav .nav-link {
    padding: 0;
}

.top-head .navbar-light .navbar-nav .nav-link .langiage-btn {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.date-picker-wrapper {
    display: flex;
    align-items: center;
}

.calendar-icon {
    margin-left: 0;
    position: absolute;
    left: -3px;
    top: -9px;
}

.calendar-icon .icon {
    width: 15px;
    height: 15px;
    fill: currentColor;
}

.date-picker .vue-daterange-picker .form-control .fa-calendar {
    display: none;
}

.header_list li:before {
    display: none;
}

.hd-wrap span {
    color: #fff;
}

.hd-wrap span:after {
    opacity: 1;
}

.hd-box li {
    display: block;
    padding: 0 !important;
    margin: 0 !important;
}

.hd-box {
    padding: 0;
    background: #363f4d;
    border-radius: 0 !important;
    border: 1px solid #fff;
}

.hd-box li:before {
    content: none !important;
}

.register-box {
    right: 0 !important;
    left: auto !important;
    top: 40px !important;
    transform: none !important;
}


input:placeholder-shown {
    border: 5px solid red;
}

.header_list>li a i {
    position: relative;
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
    position: absolute;
    top: -4px;
    left: 2px;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    line-height: 17px;
    padding: 0;
    font-size: 10px;
}

.logo-header a {
    font-size: 24px !important;
}


.hd-wrap span#LoginDropdown:after {
    content: none;
}

.hd-wrap span#RegisterDropdown:after {
    content: none;
}

.header_list.close-header-title li:before {
    content: none;
}

.header_list.close-header-title li {
    padding: 0;
    margin: 0;
}

.hd-wrap span#RegisterDropdown {
    color: #fff !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
}

html .md-form .form-control.StripeElement {
    padding: 12px 15px !important;
}

html body input.InputElement.is-complete.Input {
    letter-spacing: 0.6px;
    font-size: 15px;
}

.search_bar {
    display: none;
}

.hd-wrap .dropdown-toggle .dnon_m {
    padding-left: 5px;
}

html header.new-header {
    padding: 0;
}

.date-picker .vue-daterange-picker {
    width: 100%;
    margin-bottom: 15px;
}

html .new-header .container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1832px;
}

html .new-header .container nav.navbar {
    padding: 15px 0;
}

/* html ul.navbar-nav li.nav-item a.nav-link {
    padding: 8px 16px 8px 16px;
} */
html ul.navbar-nav li.b-nav-dropdown a.dropdown-toggle {
    padding: 0px;
}

html ul.navbar-nav li.b-nav-dropdown a.dropdown-toggle::after {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    font: normal normal normal 24px / 1 "themify";
    content: "\e64b";
    width: auto;
    height: auto;
    vertical-align: middle;
    line-height: 0.625rem;
    font-size: 0.875rem;
    margin-left: 0.3rem;
    display: block !important;
    position: absolute;
    right: 14px;
    top: 11px;
    color: #000;
    font-weight: bolder;
}

html .new-header .container nav.navbar li.form-inline {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

html .new-header .container nav.navbar .form-inline form.form-inline {
    display: flex;
    flex-wrap: nowrap;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 10px;
    width: 500px;
    position: relative;
}

html .new-header .container nav.navbar .form-inline form.form-inline input {
    border: 1px solid gray;
    width: 100%;
    padding: 10px 30px 10px 10px;
    color: rgba(0, 0, 0, 0.9);
}

html .new-header .container nav.navbar .form-inline form.form-inline button.btn-sm {
    background-color: transparent;
    position: absolute;
    right: 20px;
    padding: 0;
    border: none;
}

html .new-header .container nav.navbar .form-inline form.form-inline button.btn-sm i {
    color: rgba(0, 0, 0, 0.9);
}

.header-top.before_login_search nav.navbar {
    box-shadow: none;
    padding: 10px 0;
}

.header-top.before_login_search nav.navbar .vs__selected.vs__input-group input {
    width: 100%;
    height: 38px;
    margin: 0;
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 0 15px;
}

.header-top .vue-suggestiona {
    border-radius: 15px;
}

.header-top.before_login_search nav.navbar .vs__selected.vs__input-group {
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 15px;
    border: 0;
}

.header-top.before_login_search nav.navbar .suggestion-cross i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #42aaff !important;
}

/* .header-top.before_login_search nav.navbar #nav-collapse {
    justify-content: flex-end;
} */

.navbar-nav {
    align-items: center;
}

.dropdown-menu.logo-menu.profile-menu {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 0px;
    border-radius: 5px;
}

.b-carousel img,
.carousel .carousel-inner .carousel-item img {
    height: 215px !important;
    object-fit: cover !important;
}

.carousel-indicators li {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
}

.carousel .carousel-indicators li {
    background-color: #fff;
}

.carousel-indicators .active {
    background-color: #fff;
}

.bottom-head .head-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom-head .left-head {
    width: 100%;
}

.bottom-head .head-list .left-head ul li:nth-child(2) {
    width: calc(100% - 190px);
}

.bottom-head .head-list .left-head ul {
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0;
}

.bottom-head .head-list .left-head ul li {
    padding: 0 15px;
}

.bottom-head .head-list .left-head ul li .switch-btn {
    /* display: flex;
    gap: 15px;
    white-space: nowrap;
    overflow-y: hidden;
    align-items: center; */
    position: relative;
}

.left-head ul li .switch-btn .arrow {
    box-shadow: rgba(0, 0, 0, .3) 0 0.22222em 0.88889em;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-size: 24px;
    margin: 0;
    position: absolute;
    background: #fff;
}

.left-head ul li .switch-btn .arrow.left-arrow {
    left: -10px;
}

.left-head ul li .switch-btn .arrow.right-arrow {
    right: -10px;
}

.bottom-head .head-list .left-head ul li:first-child {
    padding-left: 0;
    border-right: 2px solid #cecece;
}

.bottom-head .head-list .left-head ul li .filter-btn {
    background-color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 9999px;
    height: 32px;
    border: 1px solid #000;
    width: 170px;
}

.bottom-head .head-list .left-head ul li .filter-btn .flex.gap-x-xs {
    column-gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom-head .head-list .left-head ul li .switch-btn .switcher {
    padding: 0;
    border-radius: 5px;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: transparent;
}

.bottom-head .head-list .left-head ul li .switch-btn .switcher .button-img {
    width: 30px;
    height: 30px;
}

.bottom-head .head-list .left-head ul li .switch-btn .switcher .button-img img {
    width: 100%;
    height: auto;
}

.bottom-head .head-list .left-head ul li .switch-btn .switcher .text-s {
    padding: 8px 0 0;
    font-size: 12px;
    width: 100%;
    line-height: 1.2;
    font-weight: 500;
}

.bottom-head .head-list .right-head .map-bg {
    background-image: url('../../public/assets/images/map-bg.png');
    width: 214px;
    padding: 8px 5px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bottom-head .head-list .right-head .map-btn {
    background-color: #70d3ff;
    color: #fff;
    border: 1px solid #70d3ff;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    font-size: 16px;
    font-weight: 600;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 5px;
}

.bottom-head .head-list .right-head .map-btn:hover {
    background: #fff;
    color: #70d3ff;
}

.bottom-head {
    padding: 8px 0;
}

.top-head {
    border-bottom: 1px solid #e7e7e8;
}

.header_list li:before {
    display: none;
}

.hd-wrap span {
    color: #fff;
}

.hd-wrap span:after {
    opacity: 1;
}

.hd-box li {
    display: block;
    padding: 0 !important;
    margin: 0 !important;
}

.hd-box {
    padding: 0;
    background: #363f4d;
    border-radius: 0 !important;
    border: 1px solid #fff;
}

.hd-box li:before {
    content: none !important;
}

.register-box {
    right: 0 !important;
    left: auto !important;
    top: 40px !important;
    transform: none !important;
}


input:placeholder-shown {
    border: 5px solid red;
}

.header_list>li a i {
    position: relative;
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
    position: absolute;
    top: -4px;
    left: 2px;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    line-height: 17px;
    padding: 0;
    font-size: 10px;
}

.logo-header a {
    font-size: 24px !important;
}


.hd-wrap span#LoginDropdown:after {
    content: none;
}

.hd-wrap span#RegisterDropdown:after {
    content: none;
}

.header_list.close-header-title li:before {
    content: none;
}

.header_list.close-header-title li {
    padding: 0;
    margin: 0;
}

.hd-wrap span#RegisterDropdown {
    color: #fff !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
}

html .md-form .form-control.StripeElement {
    padding: 12px 15px !important;
}

html body input.InputElement.is-complete.Input {
    letter-spacing: 0.6px;
    font-size: 15px;
}

.search_bar {
    display: none;
}

.hd-wrap .dropdown-toggle .dnon_m {
    padding-left: 5px;
}


.header-btm.close-header-title {
    border-top: none !important;
}

.toprow {
    display: flex;
    /* Standard syntax */
    display: -webkit-flex;
    /* Safari */
}

.topleft {
    -webkit-flex: 1;
    /* Safari */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
    /* Standard syntax */
}

.topcenter {
    -webkit-flex: 2;
    /* Safari */
    -ms-flex: 2;
    /* IE 10 */
    flex: 2;
    /* Standard syntax */
}

.topright {
    -webkit-flex: 1;
    /* Safari */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
    /* Standard syntax */
}

.toprow {
    display: flex;
    /* Standard syntax */
    display: -webkit-flex;
    /* Safari */
}

.topleft {
    -webkit-flex: 1;
    /* Safari */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
    /* Standard syntax */
}

.topcenter {
    -webkit-flex: 2;
    /* Safari */
    -ms-flex: 2;
    /* IE 10 */
    flex: 2;
    /* Standard syntax */
}

.topright {
    -webkit-flex: 1;
    /* Safari */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
    /* Standard syntax */
}


/* Header class starts */
header {
    width: 100%;
    min-height: 3em;
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    box-sizing: border-box;
    padding: 1em;
    transition: background-color 0.25s;
}


html[data-scroll="0"] header.banner li.afterLogin a i {
    color: #fff !important;
}

html[data-scroll="0"] header.banner li.afterLogin a span {
    color: #fff !important;
}

.hd-wrap span#RegisterDropdown {
    color: #fff !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
    font-size: 13px;
    padding: 13px 22px;
    line-height: 1;
    border-radius: 10rem;
    transition: all .2s ease-in-out;
    margin: 0;
    margin-left: 8px;
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

.hd-wrap span#RegisterDropdown:hover,
.hd-wrap span#RegisterDropdown:focus {
    -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
}

.hd-wrap #LoginDropdown {
    color: #fff;
    font-weight: 400;
    -webkit-transition: .35s;
    transition: .35s;
}

.hd-wrap #LoginDropdown:hover {
    color: #eee;
}

span#RegisterDropdown span {
    color: #fff !important;
}

span#LoginDropdown span {
    color: #fff !important;
    -webkit-transition: .35s;
    transition: .35s;
}

span#LoginDropdown span:hover {
    color: #eee !important;
}

html[data-scroll="0"] header.banner a.navbar-brand img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

html[data-scroll="0"] header.banner button.navbar-toggler {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

html[data-scroll="0"] header.banner #nav-collapse ul li a span {
    /* color: #fff !important; */
    color: #343a40 !important;
}

html[data-scroll="0"] header.banner a.cart-icon img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

html:not([data-scroll="0"]) body {
    padding-top: 3em;
}

html:not([data-scroll="0"]) header {
    position: fixed;
    top: 0;
    z-index: 999;
}

html:not([data-scroll="0"]) header .header-top {
    background-color: #fff;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
}

html:not([data-scroll="0"]) .hd-wrap span#RegisterDropdown {
    color: #42aaff !important;
    background-color: transparent !important;
    border: 2px solid #42aaff !important;
    font-size: 13px;
    padding: 13px 22px;
    line-height: 1;
    border-radius: 10rem;
    transition: all .2s ease-in-out;
    margin: 0;
    margin-left: 8px;
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

html:not([data-scroll="0"]) .hd-wrap #LoginDropdown {
    color: #6c757d;
    font-weight: 400;
    -webkit-transition: .35s;
    transition: .35s;
}

html:not([data-scroll="0"]) span#RegisterDropdown span {
    color: #42aaff !important;
}

html:not([data-scroll="0"]) span#LoginDropdown span {
    color: #6c757d !important;
}

html:not([data-scroll="0"]) body {
    padding-top: 3em;
}

html[data-scroll="0"] header.nobanner {
    position: sticky;
    top: 0;
    z-index: 999;
}

html[data-scroll="0"] header.nobanner .header-top {
    background-color: #fff;
    border-bottom: 0;
}

html[data-scroll="0"] header.nobanner .hd-wrap span#RegisterDropdown {
    color: #42aaff !important;
    background-color: transparent !important;
    border: 2px solid #42aaff !important;
    font-size: 13px;
    padding: 13px 22px;
    line-height: 1;
    border-radius: 10rem;
    transition: all .2s ease-in-out;
    margin: 0;
    margin-left: 8px;
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

html[data-scroll="0"] header.nobanner span#RegisterDropdown span {
    color: #42aaff !important;
}

.navbar .navbar-collapse.collapse.show {
    background-color: rgb(255, 255, 255);
    margin-right: -31px;
    margin-left: -31px;
    padding: 0px 25px;
}

html[data-scroll="0"] header.nobanner .hd-wrap #LoginDropdown {
    color: #6c757d;
    font-weight: 400;
    -webkit-transition: .35s;
    transition: .35s;
}

html[data-scroll="0"] header.nobanner span#LoginDropdown span {
    color: #6c757d !important;
}


/* Header class starts */
header {
    width: 100%;
    min-height: 3em;
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    box-sizing: border-box;
    padding: 1em;
    transition: background-color 0.25s;
}


html[data-scroll="0"] header.banner li.afterLogin a i {
    color: #fff !important;
}

html[data-scroll="0"] header.banner li.afterLogin a span {
    color: #fff !important;
}

.hd-wrap span#RegisterDropdown {
    color: #fff !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
    font-size: 13px;
    padding: 13px 22px;
    line-height: 1;
    border-radius: 10rem;
    transition: all .2s ease-in-out;
    margin: 0;
    margin-left: 8px;
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

.hd-wrap span#RegisterDropdown:hover,
.hd-wrap span#RegisterDropdown:focus {
    -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 14%), 0 4px 15px 0 rgb(0 0 0 / 12%) !important;
}

.hd-wrap #LoginDropdown {
    color: #fff;
    font-weight: 400;
    -webkit-transition: .35s;
    transition: .35s;
}

.hd-wrap #LoginDropdown:hover {
    color: #eee;
}

span#RegisterDropdown span {
    color: #fff !important;
}

span#LoginDropdown span {
    color: #fff !important;
    -webkit-transition: .35s;
    transition: .35s;
}

span#LoginDropdown span:hover {
    color: #eee !important;
}

html[data-scroll="0"] header.banner a.navbar-brand img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

html[data-scroll="0"] header.banner button.navbar-toggler {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

html[data-scroll="0"] header.banner #nav-collapse ul li a span {
    /* color: #fff !important; */
    color: #343a40 !important;
}

html[data-scroll="0"] header.banner a.cart-icon img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

html:not([data-scroll="0"]) body {
    padding-top: 3em;
}

html:not([data-scroll="0"]) header {
    position: fixed;
    top: 0;
    z-index: 999;
}

html:not([data-scroll="0"]) header .header-top {
    background-color: #fff;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
}

html:not([data-scroll="0"]) .hd-wrap span#RegisterDropdown {
    color: #42aaff !important;
    background-color: transparent !important;
    border: 2px solid #42aaff !important;
    font-size: 13px;
    padding: 13px 22px;
    line-height: 1;
    border-radius: 10rem;
    transition: all .2s ease-in-out;
    margin: 0;
    margin-left: 8px;
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

html:not([data-scroll="0"]) .hd-wrap #LoginDropdown {
    color: #6c757d;
    font-weight: 400;
    -webkit-transition: .35s;
    transition: .35s;
}

html:not([data-scroll="0"]) span#RegisterDropdown span {
    color: #42aaff !important;
}

html:not([data-scroll="0"]) span#LoginDropdown span {
    color: #6c757d !important;
}

html:not([data-scroll="0"]) body {
    padding-top: 3em;
}

html[data-scroll="0"] header.nobanner {
    position: sticky;
    top: 0;
    z-index: 999;
}

html[data-scroll="0"] header.nobanner .header-top {
    background-color: #fff;
    /* box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5); */
}

html[data-scroll="0"] header.nobanner .hd-wrap span#RegisterDropdown {
    color: #42aaff !important;
    background-color: transparent !important;
    border: 2px solid #42aaff !important;
    font-size: 13px;
    padding: 13px 22px;
    line-height: 1;
    border-radius: 10rem;
    transition: all .2s ease-in-out;
    margin: 0;
    margin-left: 8px;
    font-weight: 400;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

html[data-scroll="0"] header.nobanner span#RegisterDropdown span {
    color: #42aaff !important;
}

.navbar .navbar-collapse.collapse.show {
    background-color: rgb(255, 255, 255);
    margin-right: -31px;
    margin-left: -31px;
    padding: 0px 25px;
}

html[data-scroll="0"] header.nobanner .hd-wrap #LoginDropdown {
    color: #6c757d;
    font-weight: 400;
    -webkit-transition: .35s;
    transition: .35s;
}

html[data-scroll="0"] header.nobanner span#LoginDropdown span {
    color: #6c757d !important;
}

.vue-suggestion {
    position: relative;
    width: 100%;
    border-radius: 15px;
    padding: 0;
    color: #000;
    font-weight: 300;
    box-shadow: 0px 3px 10px 0px rgb(128 126 126 / 20%);
    background-color: #fff;
    /* border: 1px solid #f4f3f3; */
    border: 1px solid #00bff3;
    box-shadow: none !important;
}

/* li.nav-item.search-item .suggestion-cross i {
        right: 20px !important;
        color: rgb(238, 238, 238) !important;
    } */
.suggestion-cross i {
    right: 15px !important;
    color: rgb(238, 238, 238) !important;
    font-size: 16px;
}

.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
    position: absolute;
}

.vue-suggestion .vs__list .vs__list-item {
    cursor: pointer;
    width: 100%;
}

.vue-suggestion .vs__list .vs__list-item.vs__item-active {
    background-color: #f3f6fa;
}

.vue-suggestion .vs__selected input.vs__input {
    width: 100% !important;
}

.search-item {
    margin-left: 24px;
    width: 430px;
}

.cart-mobile-view {
    display: none;
}

.cart-web-view {
    display: block;
}

.profile-dropdown.btn-group .dropdown-toggle {
    padding: 0 !important;
}

.profile-dropdown.btn-group .dropdown-toggle .language-btn {
    flex-direction: row;
    gap: 8px;
}

.profile-dropdown.btn-group .dropdown-toggle .language-btn span {
    font-weight: 500;
    text-transform: capitalize;
}

.register-wrapper input[type=text],
.register-wrapper input[type=date],
.register-wrapper input[type=password],
.register-wrapper input[type=tel] {
    border: none;
    padding: 4px 10px !important;
    border: 1px solid #a3a1a1;
    border-radius: 0;
    margin-bottom: 0 !important;
    margin-top: 0px !important;
}

.register-wrapper input[type=text]:focus,
.register-wrapper input[type=date]:focus,
.register-wrapper input[type=password]:focus,
.register-wrapper input[type=tel]:focus {
    border: 2px #42aaff solid !important;
}

/* .login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 5px 0px #0000007a;
    padding-top: 25px;
    padding-bottom: 10px
} */
.login-page .main-title {
    text-align: center;
    padding: 0 0 15px 5px;
    border-bottom: 1px solid #adadad;
    margin-bottom: 20px;
}

.login-page .main-title h3 {
    line-height: 1.2;
    font-size: 20px;
    font-weight: 700;
}

.login-page .card {
    border: 0;
    padding: 0 20px;
}

.login-page .card-body {
    padding: 0;
}

.login-page .card-body .form-group {
    margin-bottom: 0;
}

.login-page .card-body .form-group.row.my-3 {
    margin: 0;
}

.login-page .text-muted {
    margin-top: 20px;
}

.login-page .card .form-title {
    margin: 10px 0 10px;
}

.login-page .custom-control-label::after {
    top: 0;
}

.login-page .custom-checkbox .custom-control-label::before {
    position: absolute;
    top: 0;
}

.login-page .card-body .form-fields .form-group .input-file .form-control {
    padding: 0;
    border: 0 !important;
}

.login-page .card-body .form-fields {
    border: 1px solid #a3a1a1;
    border-radius: 10px;
    overflow: hidden;
}

.login-page .card-body .form-fields .form-group:first-child .input-file .form-control {
    border-bottom: 1px solid #a3a1a1 !important;
    border-radius: 10px 10px 0 0;
}

.login-page .card-body .form-fields .form-group:last-child .input-file .form-control {
    border-radius: 0 0 10px 10px;
}

.login-page .card-body .form-group .input-file .btn-primary.btn-block {
    border-radius: 10px !important;
}

.login-page .form-control {
    margin-bottom: 0;
}

.login-page .form-group {
    margin-bottom: 0;
}

.jumbotron {
    background-color: #fff !important;
    border-radius: .125rem !important;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}

.login-page .skin-light.color-grey-light,
.login-page .skin-light .color-grey-light,
.login-page .skin-light .badge-grey-light,
.login-page .skin-light .bg-grey-light {
    background-color: #fbfbfb !important;
}

.login-page .skin-light .mt-70 {
    margin-top: 70px !important;
}

.login-page .skin-light .jumbotron {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 129px;
}

.login-page .skin-light {
    color: #4f4f4f;
}

.login-page .msg {
    margin: 0px 14px !important;
}

.social-login {
    margin-top: 30px;
}

.social-login .btn {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 500;
    border-radius: 10px !important;
    display: flex;
    border: 1px solid #000;
}

.social-login .btn span {
    flex: 1 1 0% !important;
}

/********Register page******/

.register-page .main-title {
    text-align: center;
    padding: 0 0 15px 5px;
    border-bottom: 1px solid #adadad;
    margin-bottom: 20px;
}

.register-page .main-title h3 {
    line-height: 1.2;
    font-size: 20px;
    font-weight: 700;
}

.register-page .card {
    border: 0;
    padding: 0 5px;
}

.register-page .card-body {
    padding: 0;
}

.register-page .card .form-title {
    margin: 10px 0 10px;
}

.steps-form-2 {
    display: table;
    width: 100%;
    position: relative;
}

.steps-form-2 .steps-row-2 {
    display: table-row;
}

.steps-form-2 .steps-row-2:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 2px;
    background-color: #7283a7;
}

.steps-form-2 .steps-row-2 .steps-step-2 {
    display: table-cell;
    text-align: center;
    position: relative;
}

.steps-form-2 .steps-row-2 .steps-step-2 p {
    margin-top: 0.5rem;
}

.steps-form-2 .steps-row-2 .steps-step-2 button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 {
    width: 70px;
    height: 70px;
    border: 2px solid #59698D;
    background-color: white !important;
    color: #59698D !important;
    border-radius: 50%;
    padding: 22px 18px 15px 18px;
    margin-top: -22px;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2:hover {
    border: 2px solid #4285F4;
    color: #4285F4 !important;
    background-color: white !important;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .fa {
    font-size: 1.7rem;
}



.jumbotron {
    background-color: #fff !important;
    border-radius: .125rem !important;
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}

.register-page .skin-light.color-grey-light,
.register-page .skin-light .color-grey-light,
.register-page .skin-light .badge-grey-light,
.register-page .skin-light .bg-grey-light {
    background-color: #fbfbfb !important;
}

.register-page .skin-light .mt-70 {
    margin-top: 70px !important;
}

.register-page .skin-light .jumbotron {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 129px;
}

.register-page .skin-light {
    color: #4f4f4f;
}

.register-page .msg {
    margin: 0px 14px !important;
}

.register-page input[type=text],
.register-page input[type=date],
.register-page input[type=password],
.register-page input[type=tel] {
    border: none;
    padding: 4px 10px !important;
    border: 1px solid #a3a1a1;
    border-radius: 0;
    margin-bottom: 0 !important;
    margin-top: 0px !important;
}

.register-page input[type=text]:focus,
.register-page input[type=date]:focus,
.register-page input[type=password]:focus,
.register-page input[type=tel]:focus {
    border: 2px #42aaff solid !important;
}

.register-page .form-group label {
    font-weight: 400 !important;
}

.register-page button#regSub,
.register-page button#bckBtn {
    font-size: 14px !important;
}

.register-page button#regSub {
    width: 100%;
    border-radius: 10px !important;
}

.register-page .form-group h5 small {
    font-size: 12px !important;
    font-weight: 300 !important;
}

.register-page .input-group .input-group-append span.input-group-text {
    height: 46px !important;
    background-color: #fff !important;
    border: 0;
    border-bottom: 1px solid #a3a1a1;
    border-left: 1px solid #a3a1a1;
    border-radius: 0 10px 0 0;
}

.register-page .input-group .input-group-append {
    margin-left: 0;
}

.register-page span.ppcls {
    font-size: 12px !important;
    text-align: justify !important;
    line-height: 1.4 !important;
    margin-bottom: 15px;
    margin-top: 5px;
}

.register-page span.bckBtntxt {
    margin-left: 5px;
}

.register-page .left-card {
    border-right: none !important;
}

.social-menu {
    margin-left: 220px;
    margin-top: 30px;
}

.social-menu ul {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
    display: flex;
}

.social-menu ul li {
    list-style: none;
    margin: 0 15px;
}

.social-menu ul li .fab {
    font-size: 30px;
    line-height: 60px;
    transition: .3s;
    color: #000;
}

.social-menu ul li .fab:hover {
    color: #fff;
}

.social-menu ul li button {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    transition: .6s;
    box-shadow: 0 5px 4px rgba(0, 0, 0, .5);
}

.social-menu ul li button:hover {
    transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) button:hover {
    /* background-color: rgba(0, 0, 0, 0.829); */
    background-color: #E4405F;
}

.social-menu ul li:nth-child(2) button:hover {
    /* background-color: #E4405F; */
    background-color: #0857dc;
}

.social-menu ul li:nth-child(3) button:hover {
    /* background-color: #0077b5; */
    background-color: #ec4333
}

.social-menu ul li:nth-child(4) button:hover {
    /* background-color: #000; */
    background-color: #1da1f2;
}

.icons-row {
    align-items: center;
    justify-content: center;
}

.icons-row .social-menu {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
}

.icons-row .social-menu ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    transform: none;
    top: 0;
    left: 0;
}

.back-button {
    position: absolute;
    left: 20px;
}

.register-page .back-button button#bckBtn {
    font-size: 18px !important;
    border: 2px solid #0000007a;
    border-radius: 100px;
    width: 26px;
    background: #fff;
    height: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-page .social-login {
    margin-top: 30px;
}

.register-page .social-login .btn {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 500;
    border-radius: 10px !important;
    display: flex;
    border: 1px solid #000;
}

.register-page .social-login .btn span {
    flex: 1 1 0% !important;
}

.register-page .card-body .form-fields .form-group .form-control {
    padding: 0;
    border: 0 !important;
}

.register-page .card-body .form-fields {
    border: 1px solid #a3a1a1;
    border-radius: 10px;
    overflow: hidden;
}

.register-page .card-body .form-fields .form-group:first-child .form-control {
    border-bottom: 1px solid #a3a1a1 !important;
    border-radius: 10px 10px 0 0;
}

.register-page .card-body .form-fields .form-group:nth-child(2) .form-control {
    border-bottom: 1px solid #a3a1a1 !important;
    border-radius: 10px 10px 0 0;
}

.register-page .card-body .form-fields .form-group:last-child .form-control {
    border-radius: 0 0 10px 10px;
}

.register-page .form-group {
    margin-bottom: 0;
}

.nav-item.login-links .logreg {
    display: flex;
    gap: 10px;
}

.nav-item.login-links .logreg .hd-wrap.dropdown.login-link {
    width: 100%;
}

.hd-wrap.register-link .dropdown-toggle,
html header.nobanner .hd-wrap.register-link span#RegisterDropdown,
html header.nobanner .hd-wrap.login-link span#LoginDropdown span,
.icon-list-info span i,
li.nav-item.icon-list-info {
    font-weight: 500;
    font-size: 16px;
    border: 1px solid rgb(231 231 232) !important;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 5px;
    color: #000;
    box-shadow: none !important;
}

.hd-wrap.register-link .dropdown-toggle:hover,
html header.nobanner .hd-wrap.register-link span#RegisterDropdown:hover,
html header.nobanner .hd-wrap.login-link span#LoginDropdown span:hover,
.icon-list-info span i:hover,
li.nav-item.icon-list-info:hover {
    background: #70d3ff !important;
    color: #fff !important;
    border-color: #70d3ff;
}

html ul.navbar-nav li.b-nav-dropdown.icon-list-info {
    padding-right: 30px;
}

.icon-list-info span i,
.icon-list-info .ti-world {
    font-weight: bolder;
    color: #000;
}

html header.nobanner .hd-wrap.login-link span#LoginDropdown span,
.icon-list-info span i,
html ul.navbar-nav li.b-nav-dropdown.icon-list-info {
    border: 0 !important;
}

html header.nobanner .hd-wrap.register-link span#RegisterDropdown span,
html header.nobanner .hd-wrap.login-link span#LoginDropdown span {
    color: #000 !important;
    font-weight: 500 !important;
}

html header.nobanner .hd-wrap.register-link span#RegisterDropdown:hover span,
html header.nobanner .hd-wrap.login-link span#LoginDropdown:hover span {
    color: #fff !important;
}

.center-tabs-layout {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    padding: 4px 0px;
    border: 1px solid #dddddd;
    min-width: 620px;
    max-width: 620px;
    width: 100%;
    margin-left: auto;
    border-radius: 32px;
}

.center-tabs-layout .title-col small {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: block;
    margin-bottom: 0px;
}

.center-tabs-layout .title-col i {
    margin: 0;
    font-size: 20px;
}

.center-tabs-layout .title-col p,
.center-tabs-layout .tabs-content p,
.guest-content .form-control {
    font-size: 16px;
    color: #575757;
    font-weight: 500;
    margin: 0px;
}

.center-tabs-layout .title-content,
.center-tabs-layout .tabs-content {
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: none;
}

.center-tabs-layout .tabs-content {
    padding: 30px 0px 0px;
}

.center-tabs-layout .tabs-content p,
.guest-content .form-control {
    color: #000;
    font-weight: 400;
}

.center-tabs-layout .tabs-content small {
    font-size: 14px;
    color: #676767;
}

.center-tabs-layout .card-text h4 {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
}

ul.suggestions-list {
    position: fixed;
    width: 620px;
    margin-left: -53px;
    margin-top: 5px;
    border-radius: .5rem;
    background: white;
    padding: 20px 30px;
    border: 1px solid rgb(231 231 232);
    box-shadow: 0 2px .75rem rgba(12, 14, 28, .08);
}

ul.suggestions-list li {
    border: 0 !important;
    font-size: 16px;
    margin: 5px 0;
}

.guest-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-right: 0 !important;
}

.guest-content:last-child {
    margin-bottom: 0;
}

.guest-content .btn.btn-info {
    padding: 0;
    width: 30px;
    height: 30px;
    font-size: 24px;
    border-radius: 0;
    background: transparent;
    border: 1px solid rgb(192 194 196);
    color: #000;
    border-radius: 100px;
}

.guest-content .form-control {
    padding: 0;
    height: 30px;
    border: 0;
    text-align: center;
    width: 60px;
    appearance: none;
}

.guest-content .form-control::-webkit-outer-spin-button,
.guest-content .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* .navbar-brand,
.navbar-collapse.collapse {
    flex: 1;
} */

.search-box-header input:placeholder-shown {
    border: 0;
}

.b-button.active {
    background-color: #007bff;
    color: #fff;
}

.language-btn {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center items horizontally */
}

.language-list ul {
    list-style-type: none;
    /* Removes default list item styling */
    padding: 0;
    margin: 0;
}

.language-list .selection-item {
    display: block;
    padding: 10px;
    border: none;
    background: transparent;
    position: relative;
    /* Position relative to place the checkmark */
}

.active-currency {
    background-color: #f0f6fd;
    /* Background color for active item */
}

.active-currency span {
    color: #0071c2;
    /* Color for spans within active item */
}

.checkmark {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    color: #0071c2;
}

.unerline {
    border: 1px solid black;
    width: 100%;
}

.rom_btn {
    border-radius: 10px !important;
    padding: 5px;
    border: 1px solid;
    height: 30px;
    width: 30px;
    border-color: rgb(234 235 237)
}

.rom_btn:active {
    background-color: #70d3ff;
    color: #ffffff;
    border-color: #70d3ff;
}

.filter-sort .type-place .heading {
    padding-bottom: 16px;
}

.filter-sort .type-place .heading h2 {
    font-size: 20px;
    margin: 0;
    line-height: 1.2;
    padding-bottom: 8px;
}

.type-place .place-listing ul {
    padding: 4px;
    margin: 0;
    border: 1px solid #dddddd;
    border-radius: 16px;
    min-height: 54px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.type-place .place-listing ul li {
    width: 33%;
}

.type-place .place-listing ul li:first-child .place-btn {
    border-radius: 12px 0 0 12px;
}

.type-place .place-listing ul li:last-child .place-btn {
    border-radius: 0 12px 12px 0;
}

.type-place .place-listing ul li .place-btn {
    text-align: center;
    outline: none;
    color: #000;
    width: 100%;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 0;
}

.type-place .place-listing ul li .place-btn:hover {
    border: 2px solid #f7f7f7;
    border-radius: 12px !important;
    background-color: #f7f7f7;
}

.type-place .place-listing ul li .place-btn.active {
    border: 2px solid #000;
    border-radius: 12px !important;
    background-color: #f7f7f7;
}

.type-place {
    margin-bottom: 30px;
}

@media (min-width:1200px) {
    div.dropdown-menu.logo-menu.profile-menu {
        min-width: 18rem;
    }

    .header-top.before_login_search nav.navbar #nav-collapse1 {
        justify-content: flex-end;
        display: inline-block !important;
        flex-grow: inherit;
        padding: 0;
        margin: 0;
        margin-left: auto;
    }

    .suggestion-cross i {
        right: 15px !important;
        color: rgb(238, 238, 238) !important;
        font-size: 16px;
    }

    .vue-suggestion .vs__list,
    .vue-suggestion .vs__loading {
        position: absolute;
    }

    .vue-suggestion .vs__list .vs__list-item {
        cursor: pointer;
        width: 100%;
    }

    .vue-suggestion .vs__list .vs__list-item.vs__item-active {
        background-color: #f3f6fa;
    }

    .vue-suggestion .vs__selected input.vs__input {
        width: 100% !important;
    }

    .search-item {
        width: 570px;
    }
}

@media (max-width: 1639px) {
    .center-tabs-layout {
        min-width: 563px;
        max-width: 563px;
    }

    .navbar .navbar-brand img {
        max-width: 190px;
    }

    .hd-wrap.register-link .dropdown-toggle,
    html header.nobanner .hd-wrap.register-link span#RegisterDropdown,
    html header.nobanner .hd-wrap.login-link span#LoginDropdown span,
    .icon-list-info span i,
    li.nav-item.icon-list-info {
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 1429px) {
    .navbar .navbar-brand img {
        max-width: 150px;
    }

    ul.suggestions-list {
        width: 445px;
        margin-left: -37px;
    }

    ul.suggestions-list li {
        border: 0 !important;
        font-size: 14px;
        margin: 5px 0;
    }

    .navbar-light .navbar-brand {
        margin-right: 10px;
    }

    .center-tabs-layout .nav-pills .nav-item .nav-link {
        padding: 0 15px;
    }

    .center-tabs-layout .title-col p,
    .center-tabs-layout .tabs-content p,
    .guest-content .form-control {
        font-size: 14px;
    }

    .center-tabs-layout {
        min-width: 445px;
        max-width: 445px;
    }

    .hd-wrap.register-link .dropdown-toggle,
    html header.nobanner .hd-wrap.register-link span#RegisterDropdown,
    html header.nobanner .hd-wrap.login-link span#LoginDropdown span,
    .icon-list-info span i,
    li.nav-item.icon-list-info {
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .top-head .navbar-light .navbar-nav .nav-link .langiage-btn {
        padding-left: 8px;
        padding-right: 8px;
    }
}

@media (max-width: 1199px) {

    .hd-wrap.register-link .dropdown-toggle,
    html header.nobanner .hd-wrap.register-link span#RegisterDropdown,
    html header.nobanner .hd-wrap.login-link span#LoginDropdown span,
    .icon-list-info span i,
    li.nav-item.icon-list-info {
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .header-top.before_login_search nav.navbar #nav-collapse1 {
        order: 3;
    }

    html .new-header .container nav.navbar {
        flex-wrap: wrap;
    }

    .navbar .navbar-brand img {
        max-width: 170px;
    }
}

@media (max-width: 1089.98px) {
    .center-tabs-layout {
        margin: 15px auto 0;
        order: 4;
    }
}

@media (max-width: 1200px) {
    .navbar-nav .nav-item:nth-child(2) {
        width: auto;
    }

    header {
        height: auto !important;
    }
}

@media (max-width: 991px) {
    .nav-item.login-links .logreg {
        flex-wrap: wrap;
    }

    ul.suggestions-list {
        width: 68%;
        padding: 15px;
    }

    .center-tabs-layout {
        min-width: 70%;
        max-width: 70%;
    }

    .nav-item.login-links .logreg .hd-wrap.dropdown.login-link:first-child {
        padding-bottom: 22px;
    }

    .cart-mobile-view {
        display: block;
        float: right;
        position: absolute;
        top: 23px !important;
        right: 85px;
    }

    .cart-web-view {
        display: block;
    }

    .loggedin {
        position: relative !important;
        right: 0 !important;
    }

    a.loggedincart {
        position: relative !important;
        top: 0px !important;
        right: 0px !important;
    }

    .alert-bell-icon {
        position: absolute;
        right: 55px;
        top: 26px;
    }

    .vue-suggestion {
        width: 100%;
    }

    .navbar-nav {
        width: 100%;
    }

    .logo-menu.profile-menu.for.mobile {
        display: block;
        width: 100%;
    }

    /* .navbar-collapse.collapse ul.navbar-nav li.nav-item {
        display: none;
    } */

    .logo-menu.profile-menu.for.mobile a.dropdown-item {
        padding-left: 15px;
        padding-right: 0;
    }

    /* .navbar-nav .nav-item:nth-child(1) {
        order: 3;
    }

    .navbar-nav .nav-item:nth-child(2) {
        order: 4;
    }

    .navbar-nav .nav-item:nth-child(3) {
        order: 2;
    }

    .navbar-nav .nav-item:nth-child(4) {
        order: 5;
    }

    .navbar-nav .nav-item:nth-child(5) {
        order: 1;
    } */

    .logo-menu {
        top: 155px !important;
    }

    header .navbar {
        display: flex !important;
        padding-left: 0;
        padding-right: 0;
    }

    header .navbar a.navbar-brand {
        width: auto;
        margin: 0 !important;
    }

    body .search-item {
        margin-left: 0;
        width: 55%;
    }

    header .navbar button.navbar-toggler {
        width: auto;
        text-align: right;
        margin: 0 !important;
    }

    .header-top.before_login_search nav.navbar .navbar-collapse.collapse {
        position: absolute;
        width: 100%;
        background: #fff;
        top: 50px;
        z-index: 9;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0;
        min-height: 185px;
    }

    .header-top.before_login_search nav.navbar .navbar-collapse.collapse .navbar-nav .nav-item {
        padding-bottom: 22px;
        text-align: left;
        width: 100%;
    }

    .header-top.before_login_search nav.navbar .languages-icons {
        margin-left: auto;
    }

    .header-top.before_login_search nav.navbar .languages-icons ul.navbar-nav {
        flex-direction: row;
    }

    .nav-item.login-links .logreg {
        display: flex;
        gap: 10px;
    }

    .hd-wrap.register-link .dropdown-toggle,
    html header.nobanner .hd-wrap.register-link span#RegisterDropdown,
    html header.nobanner .hd-wrap.login-link span#LoginDropdown span,
    .icon-list-info span i,
    li.nav-item.icon-list-info {
        font-weight: 500;
        font-size: 14px;
        border: 1px solid rgb(231 231 232) !important;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 5px;
        color: #000;
        box-shadow: none !important;
    }

    .hd-wrap.register-link .dropdown-toggle:hover,
    html header.nobanner .hd-wrap.register-link span#RegisterDropdown:hover,
    html header.nobanner .hd-wrap.login-link span#LoginDropdown span:hover,
    .icon-list-info span i:hover,
    li.nav-item.icon-list-info:hover {
        background: #70d3ff !important;
        color: #fff !important;
        border-color: #70d3ff;
    }

    html ul.navbar-nav li.b-nav-dropdown.icon-list-info {
        padding-right: 30px;
    }

    .icon-list-info span i,
    .icon-list-info .ti-world {
        font-weight: bolder;
        color: #000;
    }

    html header.nobanner .hd-wrap.login-link span#LoginDropdown span,
    .icon-list-info span i,
    html ul.navbar-nav li.b-nav-dropdown.icon-list-info {
        border: 0 !important;
    }

    html header.nobanner .hd-wrap.register-link span#RegisterDropdown span,
    html header.nobanner .hd-wrap.login-link span#LoginDropdown span {
        color: #000 !important;
        font-weight: 500 !important;
    }

    html header.nobanner .hd-wrap.register-link span#RegisterDropdown:hover span,
    html header.nobanner .hd-wrap.login-link span#LoginDropdown:hover span {
        color: #fff !important;
    }

    .center-tabs-layout {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
        border-radius: 12px;
        padding: 4px 0px;
        border: 1px solid rgb(231 231 232);
    }

    .center-tabs-layout .title-col small {
        font-size: 14px;
        font-weight: 500;
        color: #000;
        display: block;
        margin-bottom: 0px;
    }

    .center-tabs-layout .title-col i {
        margin: 0;
        font-size: 20px;
    }

    .center-tabs-layout .title-col p,
    .center-tabs-layout .tabs-content p,
    .guest-content .form-control {
        font-size: 16px;
        color: #575757;
        font-weight: 500;
        margin: 0px;
    }

    .center-tabs-layout .title-content,
    .center-tabs-layout .tabs-content {
        display: flex;
        gap: 10px;
        align-items: center;
        text-decoration: none;
        width: 100%;
    }

    .center-tabs-layout .tabs-content {
        padding: 30px 0px 0px;
    }

    .center-tabs-layout .tabs-content p,
    .guest-content .form-control {
        color: #000;
        font-weight: 400;
    }

    .center-tabs-layout .tabs-content small {
        font-size: 14px;
        color: #676767;
    }

    .center-tabs-layout .card-text h4 {
        margin: 0;
        font-weight: 400;
        font-size: 15px;
    }


    .guest-content .form-control {
        padding: 0;
        height: 30px;
        border: 0;
        text-align: center;
        width: 70px;
    }

    /* .navbar-brand,
    .navbar-collapse.collapse {
        flex: 1;
    } */

    .search-box-header input:placeholder-shown {
        border: 0;
    }

    .logo-menu.profile-menu.for.mobile {
        display: block;
        width: 100%;
    }

    /* .navbar-collapse.collapse ul.navbar-nav li.nav-item {
        display: none;
    } */

    .logo-menu.profile-menu.for.mobile a.dropdown-item {
        padding-left: 15px;
        padding-right: 0;
    }

    /* .navbar-nav .nav-item:nth-child(1) {
        order: 3;
    }

    .navbar-nav .nav-item:nth-child(2) {
        order: 4;
    }

    .navbar-nav .nav-item:nth-child(3) {
        order: 2;
    }

    .navbar-nav .nav-item:nth-child(4) {
        order: 5;
    }

    .navbar-nav .nav-item:nth-child(5) {
        order: 1;
    } */

    .logo-menu {
        top: 155px !important;
    }

    header .navbar {
        display: flex !important;
        padding-left: 0;
        padding-right: 0;
    }

    header .navbar a.navbar-brand {
        width: auto;
        margin: 0 !important;
    }

    body .search-item {
        margin-left: 0;
        width: 55%;
    }
}

@media (max-width: 767px) {
    .center-tabs-layout .daterangepicker.openscenter {
        min-width: 90% !important;
        left: -40% !important;
    }

    ul.suggestions-list {
        width: 86%;
    }

    .center-tabs-layout {
        min-width: 90%;
        max-width: 90%;
    }

    .center-tabs-layout .daterangepicker .drp-calendar {
        max-width: 270px !important;
        width: 270px !important;
    }

    .bottom-head .head-list .left-head ul li .filter-btn {
        height: 30px;
        border: 1px solid #000;
        width: 30px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    .bottom-head .head-list .left-head ul li:nth-child(2) {
        width: calc(100% - 30px);
    }

    .bottom-head .head-list .left-head ul li .filter-btn .flex.gap-x-xs span,
    .bottom-head .head-list .left-head ul li .filter-btn .flex.gap-x-xs .fa-angle-down {
        display: none;
    }
}

@media (max-width: 574px) {
    .center-tabs-layout .card-text {
        height: 400px;
        overflow-y: auto;
    }

    ul.suggestions-list {
        width: 94%;
    }

    .center-tabs-layout {
        min-width: 100%;
        max-width: 100%;
    }

    .center-tabs-layout .card-text>div {
        width: 100%;
        flex: inherit;
        border: 0 !important;
    }

    .center-tabs-layout .tab-content .tab-pane {
        padding: 20px 20px;
    }

    .center-tabs-layout .tab-content {
        width: 67%;
        margin: 0;
    }

    .navbar .navbar-brand img {
        max-width: 120px;
    }

    .top-head .navbar-light .navbar-nav .nav-link .langiage-btn,
    .hd-wrap.register-link .dropdown-toggle,
    html header.nobanner .hd-wrap.register-link span#RegisterDropdown,
    html header.nobanner .hd-wrap.login-link span#LoginDropdown span,
    .icon-list-info span i,
    li.nav-item.icon-list-info {
        padding: 5px;
    }

    header .navbar button.navbar-toggler {
        padding: 0 0 0 8px;
    }

    .center-tabs-layout {
        min-width: 100%;
    }

    .center-tabs-layout .title-content .title-col p {
        display: none;
    }

    .center-tabs-layout .nav.nav-pills {
        flex-wrap: nowrap;
    }

    .calendar-icon {
        left: 3px;
    }

    .profile-photo-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-photo-options {
        margin-top: 10px;
    }

    .profile-photo-preview img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 50%;
    }

    .capture-button {
        margin-top: 10px;
        padding: 10px 20px;
        font-size: 16px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .capture-button:hover {
        background-color: #0056b3;
    }

    video {
        width: 100%;
        height: auto;
        max-height: 300px;
        /* Adjust based on container */
    }
}

.emailnotverified {
    border: 2px solid #a3a1a1;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background: #e9c7c7;
}

.emailverified {
    border: 2px solid #a3a1a1;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background: #b7eeb7;
}

.confrmcode {
    padding: 10px;
    border: 2px solid #a3a1a1;
    border-radius: 20px;
    margin-top: 10px;
}

.confrmcode .form-control {
    border-color: #a3a1a1;
}

.new-images-listing .images-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.new-images-listing .images-card .selected-img {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    border: 1px solid #a3a1a1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-images-listing .images-card .selected-img img {
    border-radius: 50%;
    height: 200px;
    object-fit: cover;
}

.new-images-listing .images-card .btn {
    padding: 6px 12px !important;
    border-radius: 6px !important;
}

.profile-photo-section .camera-video-sec video {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    object-fit: cover;
}

.camera-video-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.profile-photo-section .camera-video-sec .btn {
    padding: 6px 12px !important;
    border-radius: 6px !important;
}

.flex-form-group {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.or-field {
    text-align: center;
    width: 100%;
    border-top: 1px solid #a3a1a1;
    position: relative;
}

.or-field span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: white;
    border-radius: 50%;
    text-transform: lowercase;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a3a1a1;
}

.flex-form-group .custom-file {
    margin-bottom: 10px;
}

.flex-form-group button {
    margin-top: 15px;
}

.flex-form-group .custom-file-input~.custom-file-label[data-browse]::after {
    display: none;
}

.flex-form-group .custom-file-label {
    color: #70d3ff;
    border-color: #70d3ff;
    text-align: center;
    padding: 0.875rem 1.5rem;
    height: 100%;
    cursor: pointer;
}

.flex-form-group .custom-file-label:hover {
    color: #212529;
    background-color: #70d3ff;
    border-color: #70d3ff;
}

.flex-form-group .custom-file {
    height: 44px;
}
</style>